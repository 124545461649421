import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  CustomersSectionId,
  actionCreatorsCustomers,
  actionCreatorsCustomersChangeData,
} from '@westondev/tableturn-core';
import { useDispatch, useSelector } from 'react-redux';

import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Button from '@app/components/common/Button';
import Divider from '@app/components/common/Divider';
import TextAreaInput from '@app/components/common/TextAreaInput';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import {
  makeSelectCustomersData,
  selectCustomersErrors,
} from '@app/state/customers/customersSelectors';
import { IGenericError } from '@app/types';
import { InputMask, format } from '@react-input/mask';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const gridSubNameSizing = {
  mb: 12,
  sm: 4,
  md: 4,
  lg: 3,
  xl: 3,
};

interface IBasicSection extends IAccordionSection {}

const PHONE_INPUT_MASK = {
  mask: '(___) ___-____',
  replacement: { _: /\d/ },
};

const BIRTHDAY_INPUT_MASK = {
  mask: 'mm/dd/yyyy',
  replacement: { m: /\d/, d: /\d/, y: /\d/ },
};

const SECTION_ID = CustomersSectionId.BASIC;

const getMemoizedCustomerData = makeSelectCustomersData(SECTION_ID);

const BasicSection = ({ ...accordionSectionProps }: IBasicSection) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateCustomer: setValue } = bindActionCreators(
    actionCreatorsCustomers,
    dispatch,
  );

  const { clearCustomersError } = bindActionCreators(
    actionCreatorsCustomersChangeData,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedCustomerData);

  const customersErrors = useSelector(selectCustomersErrors);

  const firstNameError =
    (customersErrors?.firstName?.data as IGenericError)?.message || '';
  const lastNameError =
    (customersErrors?.lastName?.data as IGenericError)?.message || '';
  const phoneNumberError =
    (customersErrors?.phoneNumber?.data as IGenericError)?.message || '';
  const hasSectionError =
    Boolean(firstNameError) ||
    Boolean(lastNameError) ||
    Boolean(phoneNumberError);

  const phoneMasked = format(data?.phoneNumber, PHONE_INPUT_MASK);

  const isValidBirthDay = !data?.dateOfBirth
    ? true
    : DateTime.fromFormat(data.dateOfBirth, 'MM/dd/yyyy').isValid;

  return (
    <>
      <AccordionSection
        title={t('loggedIn.commonTitles.basic')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        {...accordionSectionProps}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}>
          {/* Names Subsection */}

          <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('customersScreen.basic.firstName')}
                placeholder={t('customersScreen.basic.firstName')}
                value={data?.firstName}
                onChange={({ currentTarget: { value: firstName } }) =>
                  setValue(
                    {
                      firstName,
                    },
                    SECTION_ID,
                  )
                }
                caption={firstNameError}
                error={Boolean(firstNameError)}
                onFocus={() =>
                  firstNameError && clearCustomersError('firstName')
                }
              />
            </Grid.Item>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <Input
                label={t('customersScreen.basic.middleName')}
                placeholder={t('customersScreen.basic.middleName')}
                value={data?.middleName}
                onChange={({ currentTarget: { value: middleName } }) =>
                  setValue(
                    {
                      middleName,
                    },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <Input
                required
                label={t('customersScreen.basic.lastName')}
                placeholder={t('customersScreen.basic.lastName')}
                value={data?.lastName}
                onChange={({ currentTarget: { value: lastName } }) =>
                  setValue(
                    {
                      lastName,
                    },
                    SECTION_ID,
                  )
                }
                error={Boolean(lastNameError)}
                caption={lastNameError}
                onFocus={() => lastNameError && clearCustomersError('lastName')}
              />
            </Grid.Item>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <InputMask
                id="changeDateOfBirth"
                name="changeDateOfBirth"
                label={t('customersScreen.basic.dateOfBirth')}
                placeholder={t('customersScreen.basic.dateOfBirth')}
                component={Input}
                value={data?.dateOfBirth}
                showMask
                onMask={e => {
                  setValue(
                    { dateOfBirth: e.detail.input ? e.detail.value : '' },
                    SECTION_ID,
                  );
                }}
                error={!isValidBirthDay}
                caption={
                  isValidBirthDay ? '' : t('customersScreen.errors.dateOfBirth')
                }
                {...BIRTHDAY_INPUT_MASK}
              />
            </Grid.Item>
          </Grid>
          <Divider />
          <DetailsSubtitle>
            {t('customersScreen.basic.contactDetails')}
          </DetailsSubtitle>
          <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <Input
                label={t('customersScreen.basic.email')}
                placeholder={t('customersScreen.basic.email')}
                value={data?.email}
                onChange={({ currentTarget: { value: email } }) =>
                  setValue(
                    {
                      email,
                    },
                    SECTION_ID,
                  )
                }
              />
            </Grid.Item>
            <Grid.Item {...gridSubNameSizing} csx={displayFlexEndColumnStyles}>
              <InputMask
                id="changePhoneNumber"
                name="changePhoneNumber"
                label={t('customersScreen.basic.phoneNumber')}
                placeholder={t('customersScreen.basic.phoneNumber')}
                component={Input}
                value={phoneMasked}
                onMask={e => {
                  setValue({ phoneNumber: e.detail.input }, SECTION_ID);
                }}
                required
                caption={phoneNumberError}
                error={Boolean(phoneNumberError)}
                onFocus={() =>
                  phoneNumberError && clearCustomersError('phoneNumber')
                }
                {...PHONE_INPUT_MASK}
              />
            </Grid.Item>
          </Grid>
          <Divider />
          <Grid rowGap={15} columnGap={15}>
            <Grid.Item mb={9} xl={11}>
              <TextAreaInput
                label={t('customersScreen.basic.customerNotes')}
                maxLength={1000}
                value={data?.customerNotes}
                onChange={e => {
                  setValue(
                    {
                      customerNotes: e.currentTarget.value,
                    },
                    SECTION_ID,
                  );
                }}
                placeholder={t('customersScreen.basic.customerNotes')}
              />
            </Grid.Item>
            <Grid.Item
              mb={3}
              xl={1}
              csx={{
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}>
              <Grid csx={{ width: '100%' }} rowGap={10}>
                <Grid.Item>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setValue(
                        {
                          customerNotes: '',
                        },
                        SECTION_ID,
                      );
                    }}
                    disabled={data?.customerNotes === ''}
                    csx={{
                      height: '90px',
                    }}>
                    {t('commonButtons.clear')}
                  </Button>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          </Grid>
        </Box>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
