import { Theme, css } from '@emotion/react';

export const dividerContainerStyles = css({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
});

export const dividerStyles = (theme: Theme) =>
  css({
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    width: '100%',
  });

export const dividerVerticalStyles = (theme: Theme) =>
  css({
    borderRight: `1px solid ${theme.colors.lightGrey}`,
    height: '100%',
  });
