import { useRefreshCustomer } from '@westondev/tableturn-core';
import { useDispatch } from 'react-redux';

const useRefreshCustomerWeb = () => {
  const dispatch = useDispatch();
  const onRefresh = useRefreshCustomer(dispatch);

  return onRefresh;
};

export default useRefreshCustomerWeb;
