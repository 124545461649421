import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Card from '@app/components/common/Card';
import DayOfTheWeekPicker from '@app/components/common/DayOfTheWeekPicker';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import CustomSlider from '@app/components/common/Slider/CustomSlider';
import Table from '@app/components/common/Table';
import { WEEK_TABS_DATA } from '@app/components/common/Tabs/WeekTabs/types';
import TimePicker from '@app/components/common/TimePicker/TimePicker';
import Typography from '@app/components/common/Typography';
import { getDayWithErrors } from '@app/helpers/settings/timeBasedMenuTypes';
import {
  dateTimeMsToTodayDate,
  getHourMs,
  getTimeZoneWithoutContinent,
  timeZoneToText,
} from '@app/helpers/time/time';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { IGenericError } from '@app/types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DAY_OPERATIONAL_HOURS_INITIAL_STATE,
  DayOfWeek,
  HOUR_MS,
  IGeneralSettingsStore,
  SettingsSectionId,
  actionCreatorsSettings,
  dateSliderHasErrorValue,
  dayOperationalHours,
  generateTabletGeneratedId,
  settingsSelectors,
} from '@westondev/tableturn-core';
import countriesTimeZones from 'countries-and-timezones';
import { cloneDeep, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WeekTabs from '../../../../common/Tabs/WeekTabs';
import { INVALID_DAYS_INITIAL_STATE } from '../../RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/initialState';
import { sliderContainerStyles } from '../../RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/styles';
import { IInvalidDays } from '../../RegisterMode/GeneralSettings/TimeBasedMenuTypeSection/types';

const MS_8_AM = HOUR_MS * 8;
const MS_12_PM = HOUR_MS * 12;

interface IHourOperation {
  id: number;
  time: [number, number];
  bannedRanges: Array<[number, number]>;
  delete: string;
  ruleId: number | string;
}

const USA_TIME_ZONES = orderBy(
  countriesTimeZones
    .getTimezonesForCountry('US')
    .map(timeZone => timeZone.name),
  timeZone => getTimeZoneWithoutContinent(timeZone),
);

const TIME_ZONES_OPTIONS = USA_TIME_ZONES.map((timeZone, index) => {
  return {
    label: timeZoneToText(timeZone),
    value: index,
  };
});

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.HOURS_OF_OPERATION;
const getMemoizedItemData =
  makeSelectSettingsData<IGeneralSettingsStore>(SECTION_ID);

const HoursOfOperation = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );

  const { data } = useSelector(getMemoizedItemData);
  const settingsErrors = useSelector(settingsErrorsSelector);

  const endOfBusinessDayError =
    (settingsErrors?.endOfBusinessDay?.data as IGenericError)?.message || '';

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >({ tabId: 1, subTabId: null });
  const [invalidDays, setInvalidDaysState] = useState<IInvalidDays>(
    INVALID_DAYS_INITIAL_STATE,
  );

  const invalidDaysRef = useRef(invalidDays);

  const timeZoneValue = useMemo(
    () => USA_TIME_ZONES.findIndex(timeZone => timeZone === data?.timeZone),
    [data?.timeZone],
  );

  const columnHelperCustom = createColumnHelper<IHourOperation>();

  const selectedDay = useMemo(
    () =>
      activeTab?.tabId
        ? WEEK_TABS_DATA[activeTab?.tabId - 1].dayOfWeek
        : DayOfWeek.MONDAY,
    [activeTab],
  );

  const operationalDayHours = data.operationalDayHours;

  const updateTime = (
    newValue: any,
    id: number,
    field: keyof dayOperationalHours,
  ) => {
    const clonedOperationalDayHours = cloneDeep(
      store.getState().settings.changeData.data as IGeneralSettingsStore,
    )?.operationalDayHours;

    clonedOperationalDayHours[selectedDay][id][field as 'id'] = newValue;
    setValue<IGeneralSettingsStore>(
      {
        operationalDayHours: clonedOperationalDayHours,
      },
      SECTION_ID,
    );
  };

  const handleDelete = (id: number, ruleId: number | string) => {
    const operationalDayHoursCloned = cloneDeep(
      (store.getState().settings.changeData.data as IGeneralSettingsStore)
        ?.operationalDayHours,
    );
    operationalDayHoursCloned[selectedDay].splice(id, 1);

    setValue<IGeneralSettingsStore>(
      {
        operationalDayHours: operationalDayHoursCloned,
      },
      SECTION_ID,
    );

    clearInvalidDayById(ruleId);
  };

  const COLUMNS = useMemo(
    () => [
      columnHelperCustom.accessor('time', {
        header: 'Time',
        cell: info => {
          const bannedRanges = info.row.original.bannedRanges.sort(
            (a, b) => a[0] - b[0],
          );

          const hasError =
            invalidDaysRef?.current?.[selectedDay] &&
            invalidDaysRef.current[selectedDay].includes(
              info.row.original.ruleId,
            );

          return (
            <Box
              key={`slider-time-${info.row.original.id}`}
              csx={theme => sliderContainerStyles(theme, hasError)}>
              <CustomSlider
                step={900000}
                value={info.getValue()}
                min={0}
                max={86399000}
                showStepMarks={false}
                bannedRanges={bannedRanges}
                valueLabelDisplay="always"
                labelDirection="bottom"
                customValueLabel={currentVale => {
                  return DateTime.fromISO(dateTimeMsToTodayDate(currentVale))
                    .toUTC()
                    .toFormat('hh:mm a');
                }}
                debounceTimeout={200}
                onValueChange={range => {
                  if (!Array.isArray(range)) {
                    return;
                  }

                  updateTime(
                    dateTimeMsToTodayDate(range[0], true),
                    info.row.original.id,
                    'startTime',
                  );
                  updateTime(
                    dateTimeMsToTodayDate(range[1], true),
                    info.row.original.id,
                    'endTime',
                  );
                }}
                renderLimits={value => (
                  <Box csx={{ width: '70px' }}>
                    <Typography fontWeight="medium" variant="caption">
                      {DateTime.fromISO(dateTimeMsToTodayDate(value))
                        .toUTC()
                        .toFormat('hh:mm a')}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
          );
        },
        minSize: 300,
      }),
      columnHelperCustom.accessor('delete', {
        header: t('commonTexts.delete'),
        cell: info =>
          (
            <Button
              variant="danger"
              csx={{ minWidth: '50px' }}
              icon={<Icon name="MdDeleteForever" />}
              onClick={() =>
                handleDelete(info.row.original.id, info.row.original.ruleId)
              }
            />
          ) as unknown as string,
        size: 50,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedDay],
  );

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
  ) => {
    setSelectedTab({ tabId, subTabId });
  };

  const setInvalidDays = (
    newInvalidDays:
      | IInvalidDays
      | ((prevInvalidDays: IInvalidDays) => IInvalidDays),
  ) => {
    if (typeof newInvalidDays === 'function') {
      setInvalidDaysState(prevInvalidDays => {
        const newInvalidDaysResult = newInvalidDays(prevInvalidDays);
        invalidDaysRef.current = newInvalidDaysResult;
        return newInvalidDaysResult;
      });
    } else {
      invalidDaysRef.current = newInvalidDays;
      setInvalidDaysState(newInvalidDays);
    }
  };

  const clearInvalidDayById = (ruleId: string | number) => {
    setInvalidDays(prevInvalidDays => ({
      ...prevInvalidDays,
      [selectedDay]: prevInvalidDays[selectedDay].filter(
        invalidDay => invalidDay !== ruleId,
      ),
    }));
  };

  const operationalDayHoursArray: IHourOperation[] = useMemo(() => {
    return operationalDayHours[selectedDay]
      ? operationalDayHours[selectedDay].map((value, key) => {
          const bannedRanges: Array<[number, number]> = operationalDayHours[
            selectedDay
          ]
            .slice(0, key)
            .map(prevMenuTypes => [
              getHourMs(prevMenuTypes.startTime),
              getHourMs(prevMenuTypes.endTime),
            ]);
          const time: [number, number] = [
            getHourMs(value.startTime),
            getHourMs(value.endTime),
          ];

          const hasError = dateSliderHasErrorValue(time, bannedRanges);
          if (!hasError) {
            clearInvalidDayById(value.id);
          } else if (!invalidDays[selectedDay].includes(value.id)) {
            setInvalidDays(prevInvalidDays => ({
              ...prevInvalidDays,
              [selectedDay]: [...prevInvalidDays[selectedDay], value.id],
            }));
          }
          return {
            id: key,
            time,
            delete: 'canDelete',
            bannedRanges,
            ruleId: value.id,
          };
        })
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationalDayHours, selectedDay]);

  const onNewTime = () => {
    const times = operationalDayHours[selectedDay] || [];
    setValue<IGeneralSettingsStore>(
      {
        operationalDayHours: {
          ...operationalDayHours,
          [selectedDay]: [
            ...times,
            {
              ...DAY_OPERATIONAL_HOURS_INITIAL_STATE,
              day: selectedDay,
              id: generateTabletGeneratedId(),
              startTime: dateTimeMsToTodayDate(MS_8_AM, true),
              endTime: dateTimeMsToTodayDate(MS_12_PM, true),
            },
          ],
        },
      },
      SECTION_ID,
    );
  };

  const invalidDaysObject = useMemo(
    () => getDayWithErrors(operationalDayHours),
    [operationalDayHours],
  );

  const onCopyRules = (selectedDays: Record<DayOfWeek, boolean>) => {
    const clonedOperationalDayHours = cloneDeep(
      (store.getState().settings.changeData.data as IGeneralSettingsStore)
        ?.operationalDayHours,
    );
    Object.entries(selectedDays).forEach(([day, value]) => {
      if (day === selectedDay || !value) {
        return;
      }
      clonedOperationalDayHours[day as DayOfWeek] = cloneDeep(
        clonedOperationalDayHours[selectedDay as DayOfWeek],
      ).map((time, index) => {
        const existingTime = clonedOperationalDayHours[day as DayOfWeek][index];

        if (existingTime) {
          return {
            ...existingTime,
            startTime: time.startTime,
            endTime: time.endTime,
          };
        }
        return {
          ...time,
          day: day as DayOfWeek,
          id: generateTabletGeneratedId(),
        };
      });
    });
    setValue<IGeneralSettingsStore>(
      { operationalDayHours: clonedOperationalDayHours },
      SECTION_ID,
    );
  };

  return (
    <Grid columnGap={15} rowGap={15} csx={{ alignItems: 'flex-start' }}>
      <Grid.Item mb={12} sm={6} md={6} xl={3} csx={displayFlexEndColumnStyles}>
        <Dropdown
          label={t(
            'loggedIn.settingsModule.generalSettings.hoursOfOperation.timeZone.label',
          )}
          placeholder={t(
            'loggedIn.settingsModule.generalSettings.hoursOfOperation.timeZone.label',
          )}
          data={[TIME_ZONES_OPTIONS]}
          value={timeZoneValue}
          onChange={timeZone => {
            const timeZoneString = USA_TIME_ZONES[timeZone];
            setValue<IGeneralSettingsStore>(
              { timeZone: timeZoneString },
              SECTION_ID,
            );
          }}
          csx={{ display: 'flex', flexDirection: 'column' }}
        />
      </Grid.Item>
      <Grid.Item mb={12} sm={6} md={6} xl={3} csx={displayFlexEndColumnStyles}>
        <TimePicker
          label={t(
            'settingsModule.generalSettings.hoursOfOperation.endOfBusinessDay.label',
          )}
          utcValue={data?.endOfBusinessDay}
          onChange={newTime =>
            setValue<IGeneralSettingsStore>(
              { endOfBusinessDay: newTime },
              SECTION_ID,
            )
          }
          error={Boolean(endOfBusinessDayError)}
          caption={endOfBusinessDayError}
        />
      </Grid.Item>
      <Grid.Item mb={12}>
        <Card.SubCard>
          <>
            <Box csx={{ marginBottom: '10px' }}>
              <Typography
                fontWeight="medium"
                color="semanticBlue"
                align="center">
                {t(
                  'loggedIn.settingsModule.generalSettings.hoursOfOperation.title',
                )}
              </Typography>
            </Box>
            <WeekTabs
              activeTab={activeTab}
              onSelectedTabChange={handleOnSelectedTabChange}
              error={invalidDaysObject}
              csx={theme => ({
                borderRadius: 0,
                boxShadow: 'none',
                paddingInline: '5px',
                borderTop: `1px solid ${theme.colors.lighterGrey}`,
              })}
            />
            <Table
              columns={COLUMNS}
              data={data ? operationalDayHoursArray : []}
              cellCsx={{ height: '100px' }}
              containerCsx={{ border: 'none', borderRadius: 0 }}
              align={{
                delete: 'center',
              }}
              alignHeaders={{
                delete: 'center',
              }}
              showShadow={false}
              noDataComponent={
                <Box
                  csx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBlock: '20px',
                  }}>
                  <Typography align="center">
                    {t(
                      'settingsModule.registerModeSettings.general.timeBasedMenuType.noData',
                    )}
                  </Typography>
                </Box>
              }
              styleForSmallerScreens="card"
              newRowComponent={
                <Box
                  csx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                  }}>
                  <DayOfTheWeekPicker
                    selectedDays=""
                    onApply={onCopyRules}
                    disabledDays={[selectedDay]}>
                    {onToggle => (
                      <Button
                        icon={<Icon name="MdFileCopy" />}
                        csx={{ minWidth: '150px' }}
                        onClick={onToggle}
                        disabled={operationalDayHoursArray.length === 0}>
                        {t(
                          'settingsModule.registerModeSettings.general.timeBasedMenuType.copyRules.button',
                        )}
                      </Button>
                    )}
                  </DayOfTheWeekPicker>
                  <Button
                    onClick={onNewTime}
                    variant="primary"
                    csx={{ minWidth: '150px' }}
                    icon={<Icon name="MdAdd" />}>
                    {t(
                      'loggedIn.settingsModule.generalSettings.hoursOfOperation.addTime',
                    )}
                  </Button>
                </Box>
              }
            />
          </>
        </Card.SubCard>
      </Grid.Item>
    </Grid>
  );
};

export default HoursOfOperation;
