import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import { selectMenuTypes, selectSpecials } from '@app/state/menu/menuSelectors';
import { store } from '@app/state/store';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  ESpecialApplyTypes,
  filter,
  ISpecialTableRow,
  menuSpecialsTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ItemName from '../../../Items/ItemsList/ItemsTable/ItemName';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import { columnHelper, ROW_ID_PREFIX } from './types';

const SpecialsTable = ({ filterParams, t }: IMainScreenTable) => {
  const specials = useSelector(selectSpecials);
  const menuTypes = useSelector(selectMenuTypes);

  const onSort = useSort<ISpecialTableRow>('title');

  const specialsArray = menuSpecialsTableFactoryCore(
    specials,
    menuTypes,
    store.getState(),
  );

  const specialsArrayFiltered = useMemo(
    () =>
      filter<ISpecialTableRow>(
        specialsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, specialsArray],
  );

  const customFilterFn = (row: Row<ISpecialTableRow>) => {
    return (
      row.depth > 0 ||
      specialsArrayFiltered.findIndex(
        special => special.id === row.original.id,
      ) !== -1
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<ISpecialTableRow>,
    rowB: Row<ISpecialTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof ISpecialTableRow] as string,
      rowB.original[columnId as keyof ISpecialTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          const isSubRow = info.row.depth !== 0;
          const specialId = isSubRow
            ? info.row.getParentRow()?.original.id
            : info.row.original.id;
          const specialTitle =
            (isSubRow
              ? info.row.getParentRow()?.original.title
              : info.row.original.title) || '';
          return (
            <ItemName
              pathWithId={`/menu/specials/${specialId}`}
              depth={info.row.depth}
              breadcrumbTitle={specialTitle}
              status={info.row.original.status}
              isHidden={info.row.original.isHidden}
              text={info.getValue()}
              statusPath={`selectedMode.currentMenu.specials.${specialId}.active`}
              menuTypeId={info.row.original.id}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('itemsAndCombos', {
        header: t('menuScreen.categoryMain.table.headers.itemCount'),
        cell: info => {
          const value = info.getValue();
          return value ? (
            <Box csx={{ display: 'flex', width: '100%' }}>
              <Box csx={{ flex: 1 }}>
                {info.row.original.applyToEnumValue === ESpecialApplyTypes.ORDER
                  ? '-'
                  : value[0]}
              </Box>
              <Box csx={{ flex: 1 }}>
                {info.row.original.applyToEnumValue === ESpecialApplyTypes.ORDER
                  ? '-'
                  : value[1]}
              </Box>
            </Box>
          ) : (
            <OverflowText text={null} />
          );
        },
        size: 150,
      }),
      columnHelper.accessor('availability', {
        header: upperFirst(
          t('app.modals.specialsDiff.sectionTitles.availability').toLowerCase(),
        ),
        cell: info => {
          return (
            <OverflowText
              text={
                info.getValue() === undefined
                  ? t('menuScreen.specialDetails.availability.table.noData')
                  : (info.getValue() as string)
              }
            />
          );
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
      columnHelper.accessor('applyTo', {
        header: t('app.modals.discountsDiffModal.discountScheme.applyTo'),
        cell: info => {
          return <OverflowText text={info.getValue()} />;
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        size: 150,
      }),
      columnHelper.accessor('discountType', {
        header: t('app.modals.discountsDiffModal.discountScheme.type'),
        cell: info => {
          return <OverflowText text={info.getValue()} />;
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        size: 150,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        canExpandAll
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={specialsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default SpecialsTable;
