import Box from '@app/components/common/Box';
import Card from '@app/components/common/Card';
import CardsContainer from '@app/components/common/CardsContainer';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRoleSettingsStore,
  ROLE_DEFAULT_MODULE_OPTIONS,
  SettingsSectionId,
  actionCreatorsSettings,
  roleDefaultModuleFactory,
  roleModulesFactory,
  settingsSelectors,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.HOME;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const HomeSettings = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const homeScreenPermissions = data?.homeScreenPermissions;

  const moduleDropdownOptions = useMemo(
    () =>
      homeScreenPermissions
        ? roleDefaultModuleFactory(t, homeScreenPermissions)
        : [],
    [t, homeScreenPermissions],
  );

  const dropdownValue = useMemo(
    () =>
      Number(
        Object.keys(ROLE_DEFAULT_MODULE_OPTIONS).find(
          key =>
            ROLE_DEFAULT_MODULE_OPTIONS[Number(key)] === data?.defaultModule,
        ),
      ),
    [data?.defaultModule],
  );

  const defaultModules = useMemo(() => {
    return homeScreenPermissions
      ? roleModulesFactory(t, homeScreenPermissions)
      : [];
  }, [t, homeScreenPermissions]);

  return (
    <Box>
      <DetailsSubtitle>{t('loggedIn.commonTitles.home')}</DetailsSubtitle>
      <Grid columnGap={15} rowGap={15}>
        <Grid.Item>
          <Card.SubCard>
            <Typography fontWeight="medium" csx={{ marginBottom: '5px' }}>
              {t(
                'settingsModule.roleDetailsSettings.home.homeScreenDefaultModules.title',
              )}
            </Typography>
            <Typography variant="caption" csx={{ marginBottom: '10px' }}>
              {t(
                'settingsModule.roleDetailsSettings.home.homeScreenDefaultModules.description',
              )}
            </Typography>
            <CardsContainer
              data={defaultModules}
              renderItem={module => {
                return (
                  <Card.Item
                    onClick={() => {
                      setValue<IRoleSettingsStore>(
                        {
                          homeScreenPermissions: {
                            ...homeScreenPermissions,
                            [module.path]: !module.isActive,
                          },
                        },
                        SECTION_ID,
                      );
                    }}
                    isActive
                    title={module.title}
                    isSelected={module.isActive}
                  />
                );
              }}
            />
          </Card.SubCard>
        </Grid.Item>
        <Grid.Item>
          <Typography fontWeight="medium" csx={{ marginBottom: '5px' }}>
            {t(
              'settingsModule.roleDetailsSettings.home.autoOpenFromLockScreen.title',
            )}
          </Typography>
          <Typography variant="caption" csx={{ marginBottom: '10px' }}>
            {t(
              'settingsModule.roleDetailsSettings.home.autoOpenFromLockScreen.description',
            )}
          </Typography>
          <Grid>
            <Grid.Item mb={12} sm={6} md={6} lg={3}>
              <Dropdown
                placeholder={t('commonTexts.placeholderDropdown')}
                data={[moduleDropdownOptions]}
                onChange={defaultModule => {
                  const newValue =
                    defaultModule in ROLE_DEFAULT_MODULE_OPTIONS
                      ? ROLE_DEFAULT_MODULE_OPTIONS[defaultModule]
                      : undefined;
                  setValue<IRoleSettingsStore>(
                    {
                      defaultModule: newValue,
                    },
                    SECTION_ID,
                  );
                }}
                value={dropdownValue}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Box>
  );
};

export default HomeSettings;
