import { labelStyles } from '@app/components/common/Input/styles';
import OptionsModal from '@app/components/common/OptionsModal';
import { IOption } from '@app/components/common/OptionsModal/types';
import Typography from '@app/components/common/Typography';
import useReportsFilterText from '@app/hooks/useReportsFilterText';
import useRootSelector from '@app/hooks/useRootSelector';
import { IEndOfShiftReport, usersCardFactory } from '@westondev/tableturn-core';
import { useCallback, useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreatorsReports } from '@westondev/tableturn-core';

const handleReportsChange = actionCreatorsReports.handleReportsChange;

const EndOfShiftReportOwnerFilter = ({ t }: WithTranslation) => {
  const setValues = bindActionCreators(handleReportsChange, useDispatch());
  const ownerIds = useRootSelector(
    state => (state.reports.changeData?.data as IEndOfShiftReport)?.ownerIds,
  );
  const currentUsers = useRootSelector(state => state.settings.master.users);

  const userOptions = useMemo(() => {
    return usersCardFactory(currentUsers).map(user => ({
      label: user.title,
      value: user.id,
    }));
  }, [currentUsers]);

  const ownerIdsValueText = useReportsFilterText('ownerIds', ownerIds);

  const currentOwnerIdsSelected = useMemo(() => {
    if (!ownerIds?.length) return [];
    const ownerIdsSet = new Set(ownerIds ?? []);
    return userOptions.filter(v => ownerIdsSet.has(v.value));
  }, [ownerIds, userOptions]);

  const handleOnChangeDone = useCallback(
    (selectedOwnerOptions: IOption[]) => {
      if (!selectedOwnerOptions?.length) {
        setValues(prev => ({ ...prev, ownerIds: null }));
        return;
      }

      const selectedOwnerIds = selectedOwnerOptions.map(v => v.value);

      setValues(prev => ({
        ...prev,
        ownerIds: selectedOwnerIds,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Typography csx={labelStyles}>{t('reports.fields.ownerIds')}</Typography>
      <OptionsModal
        title={t('reports.fields.ownerIds')}
        customText={ownerIdsValueText}
        options={userOptions}
        currentOptionsSelected={currentOwnerIdsSelected}
        onChangeDone={handleOnChangeDone}
        clearOptionsLabel={t('commonButtons.selectAll')}
        isSearchable
        returnItemOnChange
        allowMultipleSelection
        buttonProps={{
          csx: {
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
      />
    </>
  );
};

export default EndOfShiftReportOwnerFilter;
