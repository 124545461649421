import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Modal from '@app/components/common/Modal';
import {
  CustomerAddressType,
  ICustomerAddress,
  zipCodeMask,
} from '@westondev/tableturn-core';
import { mask } from 'node-masker';
import { useEffect, useState } from 'react';
import AddressTypeSwitch from './AddressTypeSwitch';
import { AddressActions, IAddressInfoModal } from './types';

const COMMON_REQUIRED_FIELDS: Array<keyof ICustomerAddress> = [
  'streetLine1',
  'city',
  'state',
  'postalCode',
];

const LARGE_INPUT_BREAKPOINTS = {
  mb: 16,
  md: 8,
  lg: 8,
  xl: 4,
};
const SMALL_INPUT_BREAKPOINTS = {
  mb: 8,
  md: 4,
  lg: 4,
  xl: 2,
};

const ADDRESS_TYPE_INPUT_BREAKPOINTS = {
  mb: 16,
  md: 16,
  lg: 16,
  xl: 8,
};

const HOTEL_INPUT_BREAKPOINTS = {
  mb: 16,
  md: 16,
  lg: 16,
  xl: 12,
};

const REQUIRED_FIELDS: Record<
  CustomerAddressType,
  Array<keyof ICustomerAddress>
> = {
  [CustomerAddressType.HOUSE]: [...COMMON_REQUIRED_FIELDS],
  [CustomerAddressType.APARTMENT]: [
    ...COMMON_REQUIRED_FIELDS,
    'apartmentNumber',
  ],
  [CustomerAddressType.OFFICE]: [...COMMON_REQUIRED_FIELDS, 'officeName'],
  [CustomerAddressType.HOTEL]: [
    ...COMMON_REQUIRED_FIELDS,
    'hotelName',
    'roomNumber',
  ],
};

const AddressInfoModal = ({
  t,
  isActive,
  onModalClose,
  onSave,
  action,
  value,
}: IAddressInfoModal) => {
  const [newAddress, setNewAddress] = useState(value);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateFields = () => {
    const requiredFields = REQUIRED_FIELDS[newAddress.addressType];
    const newErrors: Record<string, string> = {};

    requiredFields.forEach(requiredField => {
      if (requiredField === 'postalCode') {
        if (!newAddress.postalCode) {
          return (newErrors.postalCode = `${t(
            'customersScreen.addressInfo.table.postalCode',
          )} ${t('menuScreen.itemDetails.modifierGroupsSection.required')}`);
        }
        if (newAddress.postalCode.length !== 5) {
          return (newErrors.postalCode = t(
            'settingsModule.generalSettings.errors.organizationInformation.postalCode.invalid',
          ));
        }
      }
      if (
        typeof newAddress[requiredField] === 'string'
          ? !(newAddress[requiredField] as string).trim()
          : !newAddress[requiredField]
      ) {
        newErrors[requiredField] = `${t(
          `customersScreen.addressInfo.table.${requiredField as 'streetLine1'}`,
        )} ${t('menuScreen.itemDetails.modifierGroupsSection.required')}`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (isActive) {
      setNewAddress(value);
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const updateAddressType = (addressType: CustomerAddressType) => {
    setNewAddress(prevAddress => {
      switch (addressType) {
        case CustomerAddressType.HOUSE:
          return {
            ...prevAddress,
            addressType,
            apartmentNumber: null,
            officeName: null,
            hotelName: null,
            roomNumber: null,
          };
        case CustomerAddressType.APARTMENT:
          return {
            ...prevAddress,
            addressType,
            officeName: null,
            hotelName: null,
            roomNumber: null,
          };
        case CustomerAddressType.OFFICE:
          return {
            ...prevAddress,
            addressType,
            apartmentNumber: null,
            hotelName: null,
            roomNumber: null,
          };
        case CustomerAddressType.HOTEL:
          return {
            ...prevAddress,
            addressType,
            hotelName: null,
            roomNumber: null,
          };

        default:
          return {
            ...prevAddress,
            addressType,
          };
      }
    });
  };

  const updateAddressValue = (newValues: Partial<ICustomerAddress>) => {
    setNewAddress(prevAddress => ({ ...prevAddress, ...newValues }));
  };

  const handleOnSave = () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    onSave(newAddress);
  };

  const clearError = (field: keyof ICustomerAddress) => {
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  return (
    <Modal
      isActive={isActive}
      onModalClose={onModalClose}
      dismissOnOutsideClick={false}
      size="90%"
      title={
        action === AddressActions.CREATE
          ? t('customersScreen.addressInfo.addressInfoModal.title.create')
          : t('customersScreen.addressInfo.addressInfoModal.title.edit')
      }
      footer={
        <>
          <Button
            csx={{ width: 200 }}
            variant="secondary"
            onClick={onModalClose}>
            {t('commonButtons.cancel')}
          </Button>
          <Button csx={{ width: 200 }} variant="primary" onClick={handleOnSave}>
            {t('commonButtons.save')}
          </Button>
        </>
      }>
      <Grid columns={16} columnGap={20} rowGap={20}>
        <Grid.Item
          {...ADDRESS_TYPE_INPUT_BREAKPOINTS}
          xlOffset={8}
          csx={{ justifyContent: 'flex-start' }}>
          <AddressTypeSwitch
            value={newAddress.addressType}
            onChange={addressType => updateAddressType(addressType)}
          />
        </Grid.Item>
        <Grid.Item {...LARGE_INPUT_BREAKPOINTS}>
          <Input
            label={t('customersScreen.addressInfo.table.streetLine1')}
            placeholder={t('customersScreen.addressInfo.table.streetLine1')}
            value={newAddress.streetLine1}
            required
            onFocus={() => clearError('streetLine1')}
            error={Boolean(errors.streetLine1)}
            caption={errors.streetLine1}
            onChange={e => updateAddressValue({ streetLine1: e.target.value })}
          />
        </Grid.Item>
        <Grid.Item {...LARGE_INPUT_BREAKPOINTS}>
          <Input
            label={t('customersScreen.addressInfo.table.streetLine2')}
            placeholder={t('customersScreen.addressInfo.table.streetLine2')}
            value={newAddress.streetLine2}
            onChange={e => updateAddressValue({ streetLine2: e.target.value })}
          />
        </Grid.Item>
        <Grid.Item {...LARGE_INPUT_BREAKPOINTS}>
          <Input
            label={t('customersScreen.addressInfo.table.city')}
            placeholder={t('customersScreen.addressInfo.table.city')}
            value={newAddress.city}
            required
            onFocus={() => clearError('city')}
            error={Boolean(errors.city)}
            caption={errors.city}
            onChange={e => updateAddressValue({ city: e.target.value })}
          />
        </Grid.Item>
        <Grid.Item {...SMALL_INPUT_BREAKPOINTS}>
          <Input
            label={t('customersScreen.addressInfo.table.state')}
            placeholder={t('customersScreen.addressInfo.table.state')}
            value={newAddress.state}
            required
            onFocus={() => clearError('state')}
            error={Boolean(errors.state)}
            caption={errors.state}
            onChange={e => updateAddressValue({ state: e.target.value })}
          />
        </Grid.Item>
        <Grid.Item {...SMALL_INPUT_BREAKPOINTS}>
          <Input
            label={t('customersScreen.addressInfo.table.postalCode')}
            placeholder={t('customersScreen.addressInfo.table.postalCode')}
            required
            value={
              newAddress.postalCode
                ? mask(newAddress.postalCode, zipCodeMask)
                : ''
            }
            onFocus={() => clearError('postalCode')}
            error={Boolean(errors.postalCode)}
            caption={errors.postalCode}
            onChange={e =>
              updateAddressValue({
                postalCode:
                  e.currentTarget.value.length > 5
                    ? e.currentTarget.value.slice(0, -1)
                    : e.currentTarget.value,
              })
            }
          />
        </Grid.Item>
        {newAddress.addressType === CustomerAddressType.APARTMENT && (
          <Grid.Item {...LARGE_INPUT_BREAKPOINTS}>
            <Input
              label={t('customersScreen.addressInfo.table.apartmentNumber')}
              placeholder={t(
                'customersScreen.addressInfo.table.apartmentNumber',
              )}
              value={newAddress.apartmentNumber || ''}
              required
              onFocus={() => clearError('apartmentNumber')}
              error={Boolean(errors.apartmentNumber)}
              caption={errors.apartmentNumber}
              onChange={e =>
                updateAddressValue({ apartmentNumber: e.target.value })
              }
            />
          </Grid.Item>
        )}
        {newAddress.addressType === CustomerAddressType.OFFICE && (
          <Grid.Item mb={16}>
            <Input
              label={t('customersScreen.addressInfo.table.officeName')}
              placeholder={t('customersScreen.addressInfo.table.officeName')}
              value={newAddress.officeName || ''}
              required
              onFocus={() => clearError('officeName')}
              error={Boolean(errors.officeName)}
              caption={errors.officeName}
              onChange={e => updateAddressValue({ officeName: e.target.value })}
            />
          </Grid.Item>
        )}
        {newAddress.addressType === CustomerAddressType.HOTEL && (
          <>
            <Grid.Item {...HOTEL_INPUT_BREAKPOINTS}>
              <Input
                label={t('customersScreen.addressInfo.table.hotelName')}
                placeholder={t('customersScreen.addressInfo.table.hotelName')}
                value={newAddress.hotelName || ''}
                required
                onFocus={() => clearError('hotelName')}
                error={Boolean(errors.hotelName)}
                caption={errors.hotelName}
                onChange={e =>
                  updateAddressValue({ hotelName: e.target.value })
                }
              />
            </Grid.Item>
            <Grid.Item {...LARGE_INPUT_BREAKPOINTS}>
              <Input
                label={t('customersScreen.addressInfo.table.roomNumber')}
                placeholder={t('customersScreen.addressInfo.table.roomNumber')}
                value={newAddress.roomNumber || ''}
                required
                onFocus={() => clearError('roomNumber')}
                error={Boolean(errors.roomNumber)}
                caption={errors.roomNumber}
                onChange={e =>
                  updateAddressValue({ roomNumber: e.target.value })
                }
              />
            </Grid.Item>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default AddressInfoModal;
