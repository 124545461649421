import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import {
  selectCombos,
  selectItemGroups,
  selectItems,
} from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IItemGroupTableRow,
  menuItemGroupsTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const ItemGroupsTable = ({ filterParams, t }: IMainScreenTable) => {
  const itemGroups = useSelector(selectItemGroups);
  const items = useSelector(selectItems);
  const combos = useSelector(selectCombos);

  const onSort = useSort<IItemGroupTableRow>('title');

  const itemGroupsArray = menuItemGroupsTableFactoryCore(
    itemGroups,
    items,
    combos,
  );

  const itemGroupsArrayFiltered = useMemo(
    () =>
      filter<IItemGroupTableRow>(
        itemGroupsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, itemGroupsArray],
  );

  const customFilterFn = (row: Row<IItemGroupTableRow>) => {
    return (
      row.depth > 0 ||
      itemGroupsArrayFiltered.findIndex(
        itemGroup => itemGroup.id === row.original.id,
      ) !== -1
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<IItemGroupTableRow>,
    rowB: Row<IItemGroupTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof IItemGroupTableRow] as string,
      rowB.original[columnId as keyof IItemGroupTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/combos/item-groups/${info.row.original.id}`}
            status={info.row.original.isActive}
            statusPath={`selectedMode.currentMenu.itemGroups.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('items', {
        header: t('menuScreen.ingredientsMain.table.headers.items'),
        cell: info => (
          <OverflowText
            text={info.getValue() || t('register.modals.itemDetails.noItems')}
          />
        ),
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
      columnHelper.accessor('combos', {
        header: t('menuScreen.reportingGroupsMain.table.headers.combos'),
        cell: info => (
          <OverflowText
            text={
              info.getValue() ||
              t('menuScreen.reportingGroupsMain.table.noCombos')
            }
          />
        ),
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={itemGroupsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ItemGroupsTable;
