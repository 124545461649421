import {
  actionCreatorsReports,
  IReportsStore,
  TReportsPostRequest,
} from '@westondev/tableturn-core';
import { AppThunk } from '../store';
import { getPathWithOrgData } from '@app/helpers/navigation';
import { getRequiredActionsData } from '../menu/menuWebActions';
import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import router from '@app/router/router';

const {
  cancelReportsChangesOnPress,
  checkReportsForChangesAndNavigate,
  saveReportsChangesOnSavePress,
  deleteReport,
  fetchReport,
  getLocationsToApplyReportsChanges,
  emailReport,
} = actionCreatorsReports;

const postReportsSave: TReportsPostRequest = (id, bucket) => () => {
  const url = getPathWithOrgData(REPORTS_SCREEN_BY_BUCKET_NAME[bucket]);
  router.navigate(-1);
  if (!id) {
    return;
  }

  setTimeout(() => {
    router.navigate(`${url}/${id}`);
  }, 0);
};

export const onReportPDF = (
  data: ArrayBuffer | Uint8Array,
): Promise<string> => {
  try {
    const file = new Blob([data], { type: 'application/pdf' });
    return Promise.resolve(URL.createObjectURL(file));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const checkForReportsChangesAndNavigateWeb =
  (
    onContinue: () => void,
    onRefresh: () => void,
    onContinueResetChangeData = true,
    isSavePressed = false,
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    return dispatch(
      checkReportsForChangesAndNavigate(
        onContinue,
        onRefresh,
        () => () => null,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
        onContinueResetChangeData,
        isSavePressed,
      ) as unknown as AppThunk<void>,
    );
  };

export const saveReportsChangesOnSaveClickWeb =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    dispatch(
      saveReportsChangesOnSavePress(
        () => null,
        postReportsSave,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
        undefined,
      ),
    ) as unknown as AppThunk;
  };

export const cancelReportsChangesOnPressWeb =
  (): AppThunk => async dispatch => {
    const onNavigate = (bucketName: keyof IReportsStore) => {
      const baseRoute = REPORTS_SCREEN_BY_BUCKET_NAME[bucketName];
      if (!baseRoute) return;
      const route = getPathWithOrgData(baseRoute);
      router.navigate(route);
    };

    dispatch(cancelReportsChangesOnPress(onNavigate));
  };

export const deleteReportWeb =
  (id: number, bucket: keyof IReportsStore, navigate = false): AppThunk =>
  (dispatch, getState) => {
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(getState());

    dispatch(
      deleteReport(
        id,
        bucket,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
      ),
    )
      .then(() => {
        if (!navigate) return;

        const url = getPathWithOrgData(REPORTS_SCREEN_BY_BUCKET_NAME[bucket]);
        router.navigate(url);
      })
      .catch(() => null);
  };

export const fetchReportPDFWeb = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const { organizationId, currentLocationId, licenseId } =
    getRequiredActionsData(state);

  const orgUserId = state.loggedInUser.organizationUserId;

  return dispatch(
    fetchReport(
      onReportPDF,
      orgUserId,
      organizationId,
      currentLocationId,
      licenseId,
      webErrorCodesDispatcher,
    ),
  );
};

export const getLocationsToApplyReportsChangesWeb =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const { currentLocationId } = getRequiredActionsData(state);

    return dispatch(getLocationsToApplyReportsChanges(currentLocationId));
  };

export const emailReportMobile =
  (orgUserIds: number[] | null): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (!orgUserIds?.length) return;
    const state = getState();

    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);

    const orgUserId = state.loggedInUser.organizationUserId;

    const report = state.reports.changeData.data;
    const bucket = state.reports.changeData.bucket;

    if (!report || !bucket) return;

    return dispatch(
      emailReport(
        orgUserIds,
        bucket,
        report,
        orgUserId,
        organizationId,
        currentLocationId,
        licenseId,
        webErrorCodesDispatcher,
      ),
    );
  };
