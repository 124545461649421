import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from '../../Box';
import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import Typography from '../../Typography';
import { descriptionStyles } from '../SettingsDiffConfirmationModal/styles';
import CustomerDiffModal from './CustomerDiffModal';

interface IDifferencesModal extends IConfirmationModal, WithTranslation {}

const CustomersDiffConfirmationModal = ({
  title,
  active,
  description,
  onCancel,
  onSuccess,
  btnCancelText,
  btnSuccessText,
  runCancelOnClickOutside,
  t,
  hideCancel,
  hideSuccess,
}: IDifferencesModal) => {
  // Redux
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
  };

  const theme = useTheme();
  const boxTextColor = theme.colors.darkBlue;

  return (
    <Modal
      isActive={active}
      size={1225}
      onModalClose={handleOnModalClose}
      title={
        <Box>
          <Typography
            variant="subtitle"
            fontWeight="medium"
            csx={{ marginBottom: '2px' }}>
            {title}
          </Typography>
          <Typography color={boxTextColor}>
            {t('customersScreen.diffModal.title')}
          </Typography>
        </Box>
      }
      footer={
        <>
          {btnCancelText && (
            <Button variant="secondary" onClick={handleOnModalClose}>
              {t('commonButtons.cancel')}
            </Button>
          )}
          {!hideCancel && (
            <Button variant="secondary" onClick={handleOnCancel}>
              {btnCancelText ? btnCancelText : t('commonButtons.cancel')}
            </Button>
          )}
          {!hideSuccess && (
            <Button
              variant="primary"
              icon={<Icon name="MdArrowForward" />}
              iconPosition="right"
              onClick={handleOnModalSuccess}>
              {btnSuccessText ? btnSuccessText : t('commonButtons.continue')}
            </Button>
          )}
        </>
      }>
      <Box csx={descriptionStyles}>
        <Box csx={{ marginRight: '12px' }}>
          <Icon name="MdWarning" color="darkYellow" />
        </Box>
        <Typography>{description}</Typography>
      </Box>
      <CustomerDiffModal />
    </Modal>
  );
};

export default CustomersDiffConfirmationModal;
