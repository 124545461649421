import Input from '../Input';
import { IInput } from '../Input/Input';
import { DateTime } from '@westondev/tableturn-core';

interface DatePickerProps extends Omit<IInput, 'onChange' | 'value' | 'type'> {
  value: string;
  onChange: (newValue: string) => void;
  minValue?: string;
  maxValue?: string;
}

const DateTimePicker = ({
  value,
  onChange,
  maxValue,
  minValue,
  ...props
}: DatePickerProps) => {
  const settingsTimeZone = 'America/New_York';
  const getLocaleValue = (utcDateTime: string | null | undefined) => {
    if (!utcDateTime) {
      return undefined;
    }

    const dt = DateTime.fromISO(utcDateTime, { zone: 'utc' });
    // eslint-disable-next-line quotes
    return dt.setZone(settingsTimeZone).toFormat("yyyy-MM-dd'T'HH:mm");
  };

  const getUTCValue = (localDateTime: string | null | undefined) => {
    if (!localDateTime) {
      return undefined;
    }

    const dt = DateTime.fromISO(localDateTime, { zone: settingsTimeZone });
    return dt.setZone('utc').toISO();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedDateTime = e.target.value;
    const convertedDateTime = getUTCValue(inputtedDateTime);
    onChange(convertedDateTime || '');
  };

  const currentValue = getLocaleValue(value);
  const min = getLocaleValue(minValue);
  const max = getLocaleValue(maxValue);

  return (
    <Input
      {...props}
      type="datetime-local"
      value={currentValue}
      onChange={handleChange}
      max={max}
      min={min}
    />
  );
};

export default DateTimePicker;
