import Checkbox from '@app/components/common/Checkbox';
import DateTimePicker from '@app/components/common/DateTimePicker/DateTimePicker';
import Divider from '@app/components/common/Divider';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import { labelStyles } from '@app/components/common/Input/styles';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  actionCreatorsReports,
  EGroupByItemComboSalesReport,
  EReportPeriod,
  IItemComboSalesReport,
  IReportsStore,
  ISalesOverviewReport,
  periodDropdownOptions,
  periodToPeriodValue,
  periodValueToPeriod,
  TReportsErrors,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import EndOfShiftReportOwnerFilter from './EndOfShiftReportFilters/EndOfShiftReportOwnerFilter';
import ReportFiltersSubsection from './ReportFiltersSubsection';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import Box from '@app/components/common/Box';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';

interface IReportSettingsSection extends IAccordionSection, WithTranslation {
  bucket: keyof IReportsStore;
}

const ReportSettingsSection = ({
  t,
  bucket,
  ...props
}: IReportSettingsSection) => {
  const dispatch = useDispatch();
  const setValues = bindActionCreators(
    actionCreatorsReports.handleReportsChange,
    dispatch,
  );
  const resetError = bindActionCreators(
    actionCreatorsReports.resetReportsFieldErrors,
    dispatch,
  );

  const report = useRootSelector(state => state.reports.changeData.data);

  const nameError = useRootSelector(
    state => state.reports.changeData.errors?.name ?? '',
  );
  const groupByError = useRootSelector(
    state =>
      (
        state.reports.changeData
          .errors as TReportsErrors<'itemComboSalesReports'>
      )?.groupBy ?? '',
  );

  const startDateError = useRootSelector(
    state => state.reports.changeData.errors?.startDate ?? '',
  );
  const endDateError = useRootSelector(
    state => state.reports.changeData.errors?.endDate ?? '',
  );

  const endOfBusinessDayTime = useRootSelector(state => {
    const eodTime = new Date(
      state.settings.selectedMode.currentSettings.general.endOfBusinessDay,
    );
    const eodLocaleTime = eodTime?.toLocaleTimeString('en', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    });
    return eodLocaleTime;
  });

  if (!report) return null;

  const activeSwitch = (
    <Switch
      checked={report.isActive}
      onChange={v =>
        setValues(_values => ({
          ..._values,
          isActive: v,
        }))
      }
      showDefaultLabel
    />
  );

  return (
    <AccordionSection
      title={t('reports.addEditScreen.reportSettings')}
      required
      hasError={!!nameError || !!startDateError || !!endDateError}
      titleTextContainerCsx={{ minWidth: '200px' }}
      collapsedHeaderComponent={
        <Box
          csx={[
            {
              display: 'flex',
              height: '100%',
              gap: '10px',
            },
          ]}>
          <Divider
            direction="vertical"
            lineCsx={theme => ({
              borderColor: theme.colors.lightGrey,
            })}
          />
          {activeSwitch}
        </Box>
      }
      {...props}>
      <Box
        csx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}>
        {activeSwitch}

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12} sm={6} md={3}>
            <Input
              required
              value={report.name}
              onChange={e =>
                setValues(_values => ({
                  ..._values,
                  name: e.currentTarget.value,
                }))
              }
              label={t('reports.fields.name')}
              error={!!nameError}
              caption={nameError && t(`validations.${nameError}` as ParseKeys)}
              onFocus={() => resetError('name')}
            />
          </Grid.Item>

          {bucket === 'endOfShiftReports' && (
            <Grid.Item mb={12} sm={6} md={3}>
              <EndOfShiftReportOwnerFilter />
            </Grid.Item>
          )}
        </Grid>

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />
        <Box>
          <Typography variant="subtitle" fontWeight="medium" color="darkBlue">
            {t('reports.fields.period')}
          </Typography>
          <Typography variant="caption" color="darkestGrey">
            {t('reports.fields.periodSubtitle', { time: endOfBusinessDayTime })}
          </Typography>
        </Box>
        <Grid rowGap={20} columnGap={20}>
          <Grid.Item mb={12} sm={6} md={3}>
            <Typography csx={labelStyles}>Report Period</Typography>
            <Dropdown
              data={[periodDropdownOptions]}
              onChange={v =>
                setValues({
                  ...report,
                  period:
                    periodValueToPeriod[v as keyof typeof periodValueToPeriod],
                })
              }
              value={periodToPeriodValue[report.period]}
            />
          </Grid.Item>
          {report.period === EReportPeriod.CUSTOM && (
            <>
              <Grid.Item mb={12} sm={6} md={3}>
                <Typography csx={labelStyles}>
                  {t('reports.fields.startDate')}
                </Typography>
                <DateTimePicker
                  required
                  value={report.startDate}
                  maxValue={report.endDate}
                  onChange={v =>
                    setValues(_values => ({
                      ..._values,
                      startDate: v,
                    }))
                  }
                  error={!!startDateError}
                  caption={
                    startDateError &&
                    t(`validations.${startDateError}` as ParseKeys)
                  }
                  onFocus={() => resetError('startDate')}
                />
              </Grid.Item>

              <Grid.Item mb={12} sm={6} md={3}>
                <Typography csx={labelStyles}>
                  {t('reports.fields.endDate')}
                </Typography>
                <DateTimePicker
                  required
                  value={report.endDate}
                  minValue={report.startDate}
                  onChange={v =>
                    setValues(_values => ({
                      ..._values,
                      endDate: v,
                    }))
                  }
                  error={!!endDateError}
                  caption={
                    endDateError &&
                    t(`validations.${endDateError}` as ParseKeys)
                  }
                  onFocus={() => resetError('endDate')}
                />
              </Grid.Item>
            </>
          )}
        </Grid>

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />

        <Typography variant="subtitle" fontWeight="medium" color="darkBlue">
          Filters
        </Typography>
        <ReportFiltersSubsection />

        <Divider
          lineCsx={theme => ({
            borderColor: theme.colors.lightGrey,
          })}
        />
        <Typography variant="subtitle" fontWeight="medium" color="darkBlue">
          Display Settings
        </Typography>
        <Grid rowGap={20} columnGap={20}>
          {bucket === 'itemComboSalesReports' && (
            <Grid.Item mb={12} sm={6} md={3}>
              <Typography csx={labelStyles}>
                {t('reports.fields.groupBy')}
              </Typography>
              <Dropdown
                data={[
                  [
                    {
                      label: t('reports.fields.groupByOptions.category'),
                      value: 0,
                    },
                    {
                      label: t(
                        'reports.fields.groupByOptions.reporting_groups',
                      ),
                      value: 1,
                    },
                  ],
                ]}
                value={
                  (report as IItemComboSalesReport).groupBy ===
                  EGroupByItemComboSalesReport.CATEGORY
                    ? 0
                    : 1
                }
                onChange={v => {
                  setValues({
                    ...report,
                    groupBy:
                      v === 0
                        ? EGroupByItemComboSalesReport.CATEGORY
                        : EGroupByItemComboSalesReport.REPORTING_GROUPS,
                  });
                  resetError<'itemComboSalesReports'>('groupBy');
                }}
                error={!!groupByError}
                caption={
                  groupByError && t(`validations.${groupByError}` as ParseKeys)
                }
              />
            </Grid.Item>
          )}
          {bucket !== 'itemComboSalesReports' && (
            <>
              <Grid.Item mb={12} sm={6} md={3}>
                <Checkbox
                  label={t('reports.fields.showSalesByMenuType')}
                  labelCsx={theme => ({
                    color: theme.colors.black,
                    fontSize: theme.fontSizes.body,
                    fontWeight: theme.fontWeights.medium,
                  })}
                  checked={
                    (report as ISalesOverviewReport).showSalesByMenuType ??
                    false
                  }
                  onChange={v =>
                    setValues(_report => ({
                      ..._report,
                      showSalesByMenuType: v,
                    }))
                  }
                />
              </Grid.Item>
              <Grid.Item mb={12} sm={6} md={3}>
                <Checkbox
                  label={t('reports.fields.showSalesByOrderType')}
                  labelCsx={theme => ({
                    color: theme.colors.black,
                    fontSize: theme.fontSizes.body,
                    fontWeight: theme.fontWeights.medium,
                  })}
                  checked={
                    (report as ISalesOverviewReport).showSalesByOrderType ??
                    false
                  }
                  onChange={v => {
                    setValues(_report => ({
                      ..._report,
                      showSalesByOrderType: v,
                    }));
                  }}
                />
              </Grid.Item>
            </>
          )}
        </Grid>
      </Box>
    </AccordionSection>
  );
};

export default ReportSettingsSection;
