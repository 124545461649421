import Box from '@app/components/common/Box';
import Tag86 from '@app/components/common/Card/ItemButton/Tag86';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  selectCategories,
  selectItems,
  selectMenuTypes,
  selectModifierGroups,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import {
  currentPrepStationsSelector,
  currentTaxesSettingsSelector,
} from '@app/state/selectors/settingsSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IItemTableRow,
  IPair,
  iPairSortingValues,
  menuItemTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CategoryColumn from './CategoryColumn';
import ItemName from './ItemName';
import OverflowText from './OverflowText';
import TaxColumn from './TaxColumn';
import { columnHelper, IMainScreenTable, ROW_ID_PREFIX } from './types';

const ItemsTable = ({ filterParams, t }: IMainScreenTable) => {
  const items = useSelector(selectItems);
  const categories = useSelector(selectCategories);
  const subcategories = useSelector(selectSubcategories);
  const menuTypes = useSelector(selectMenuTypes);
  const taxes = useSelector(currentTaxesSettingsSelector);
  const modifierGroups = useSelector(selectModifierGroups);
  const prepStations = useSelector(currentPrepStationsSelector);

  const onSort = useSort<IItemTableRow>('title');

  const itemsArray = menuItemTableFactoryCore(
    items,
    categories,
    subcategories,
    menuTypes,
    taxes,
    modifierGroups,
    prepStations,
  );

  const itemsArrayFiltered = useMemo(
    () =>
      filter<IItemTableRow>(
        itemsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
        ['upc'],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, itemsArray],
  );

  const customFilterFn = (row: Row<IItemTableRow>) => {
    return (
      row.depth > 0 ||
      itemsArrayFiltered.findIndex(item => item.id === row.original.id) !== -1
    );
  };

  const iPairSortingFn = (
    rowA: Row<IItemTableRow>,
    rowB: Row<IItemTableRow>,
    columnId: string,
  ) => {
    const validateTaxInclusiveSort = () => {
      if (rowA.original.taxInclusive && rowB.original.taxInclusive) {
        return 0;
      } else if (rowA.original.taxInclusive) {
        return -1;
      }
      return 1;
    };

    return iPairSortingValues(
      rowA.original?.[columnId as keyof IItemTableRow] as IPair[],
      rowB.original?.[columnId as keyof IItemTableRow] as IPair[],
      columnId === 'taxes' ? () => validateTaxInclusiveSort() : undefined,
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<IItemTableRow>,
    rowB: Row<IItemTableRow>,
    columnId: keyof IItemTableRow,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId] as string,
      rowB.original[columnId] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          const isSubRow = info.row.depth !== 0;
          const itemId = isSubRow
            ? info.row.getParentRow()?.original.id
            : info.row.original.id;
          const itemTitle =
            (isSubRow
              ? info.row.getParentRow()?.original.title
              : info.row.original.title) || '';
          return (
            <ItemName
              pathWithId={`/menu/items/${itemId}`}
              depth={info.row.depth}
              breadcrumbTitle={itemTitle}
              status={info.row.original.status}
              isHidden={info.row.original.isHidden}
              text={info.getValue()}
              statusPath={`selectedMode.currentMenu.items.${itemId}.active`}
              menuTypeId={info.row.original.menuTypeId[0]}
            />
          );
        },
        size: 250,
      }),
      columnHelper.accessor('customId', {
        header: t('app.modals.itemsDiff.master.customId'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        size: 140,
      }),
      columnHelper.accessor('price', {
        header: t('menuScreen.itemsMain.table.headers.price'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 150,
      }),
      columnHelper.accessor('quantity', {
        header: t('menuScreen.itemsMain.table.headers.quantity'),
        cell: info =>
          info.getValue() === null && info.row.depth === 0 ? (
            <Tag86 />
          ) : (
            <Typography>
              {info.row.depth === 0 ? info.getValue() : '-'}
            </Typography>
          ),
        minSize: 50,
        sortDescFirst: false,
      }),
      columnHelper.accessor('menuTypeId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('categoryId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('subcategoryId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('categoriesSubcategories', {
        header: t('menuScreen.itemsMain.table.headers.categoriesSubcategories'),
        cell: info => {
          return <CategoryColumn value={info.getValue()} />;
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        minSize: 230,
      }),

      columnHelper.accessor('modifierGroups', {
        header: t('menuScreen.itemsMain.table.headers.modifierGroups'),
        cell: info => {
          return (
            <OverflowText
              text={
                info.getValue() === undefined
                  ? t('menuScreen.itemsMain.table.noModifierGroups')
                  : (info.getValue() as string)
              }
            />
          );
        },
        sortDescFirst: false,
        sortingFn: (rowA, rowB, columnId) =>
          nullUndefinedSortingFn(rowA, rowB, columnId as keyof IItemTableRow),
        minSize: 200,
      }),
      columnHelper.accessor('printerLocations', {
        header: t('menuScreen.itemsMain.table.headers.printerLocations'),
        cell: info => {
          return (
            <OverflowText
              text={
                info.getValue() === undefined
                  ? t('menuScreen.itemsMain.table.noPrinterLocations')
                  : (info.getValue() as string)
              }
            />
          );
        },
        sortDescFirst: false,
        sortingFn: (rowA, rowB, columnId) =>
          nullUndefinedSortingFn(rowA, rowB, columnId as keyof IItemTableRow),
        minSize: 180,
      }),
      columnHelper.accessor('taxes', {
        header: t('menuScreen.itemsMain.table.headers.taxes'),
        cell: info => {
          const taxInclusive = info.row.original.taxInclusive;
          return (
            <Box csx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              {taxInclusive && <Icon size="35px" name="TaxInclusive" />}
              <TaxColumn
                truncateLength={taxInclusive ? 8 : undefined}
                value={info.getValue()}
              />
            </Box>
          );
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        minSize: 250,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        canExpandAll
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={itemsArray}
        manualSorting={false}
        columnVisibility={{
          menuTypeId: false,
          categoryId: false,
          subcategoryId: false,
        }}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '75px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ItemsTable;
