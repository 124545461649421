import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Typography from '@app/components/common/Typography';
import DetailsSubtitle from '@app/components/LoggedIn/DetailsSubtitle';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  IRoleSettingsStore,
  SettingsSectionId,
  actionCreatorsSettings,
  settingsSelectors,
  valueToViewShiftSummary,
  viewShiftSummaryOptions,
  viewShiftSummaryToValue,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { makeSelectSettingsData } = settingsSelectors;

const SECTION_ID = SettingsSectionId.END_OF_DAY;
const getMemoizedRoleData =
  makeSelectSettingsData<IRoleSettingsStore>(SECTION_ID);

const EndOfDaySettings = ({ t }: WithTranslation) => {
  const { updateGenericSettings: setValue } = bindActionCreators(
    actionCreatorsSettings,
    useDispatch(),
  );
  const { data } = useSelector(getMemoizedRoleData);

  const viewShiftSummary = data?.endOfDayPermissions?.viewShiftSummary;

  return (
    <>
      <DetailsSubtitle>
        {t('settingsModule.roleDetailsSettings.endOfDay.title')}
      </DetailsSubtitle>
      <Typography fontWeight="medium" csx={{ marginBottom: '10px' }}>
        {t(
          'settingsModule.roleDetailsSettings.endOfDay.endOfDayPermissions.viewShiftSummary.label',
        )}
      </Typography>
      <Grid>
        <Grid.Item mb={12} sm={6} md={6} lg={3}>
          <Dropdown
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[viewShiftSummaryOptions]}
            onChange={vss => {
              setValue<IRoleSettingsStore>(
                {
                  endOfDayPermissions: {
                    viewShiftSummary: valueToViewShiftSummary[vss],
                  },
                },
                SECTION_ID,
              );
            }}
            value={viewShiftSummaryToValue[viewShiftSummary]}
          />
        </Grid.Item>
      </Grid>
    </>
  );
};

export default EndOfDaySettings;
