import { webErrorCodesDispatcher } from '@app/helpers/apiCall';
import { getPathWithOrgData } from '@app/helpers/navigation';
import router from '@app/router/router';
import {
  actionCreatorsCustomers,
  actionCreatorsCustomersChangeData,
  CUSTOMER_INITIAL_STATE,
  CustomersSectionId,
  FormMode,
  getCustomersVersionWithStateCore,
} from '@westondev/tableturn-core';
import cloneDeep from 'lodash/cloneDeep';
import { NavigateFunction } from 'react-router-dom';
import { setIsLoadingModal } from '../app/actions';
import { getRequiredActionsData } from '../menu/menuWebActions';
import { AppThunk } from '../store';
import { selectCustomers } from './customersSelectors';
import { ILoadCustomerChangeData } from './types';

const { setCustomersChangeData, resetCustomersChangeData } =
  actionCreatorsCustomersChangeData;
const {
  saveCustomerChangesOnSavePress: saveCustomerChangesOnSavePressCore,
  checkForCustomerChangesAndNavigate: checkForCustomerChangesAndNavigateCore,
  cancelCustomerChangesOnCancelPress: cancelCustomerChangesOnCancelPressCore,
  updateCustomerChangeData,
} = actionCreatorsCustomers;

export const loadCustomersChangeData =
  ({ id = 0 }: ILoadCustomerChangeData): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId } = getRequiredActionsData(state);

    if (!organizationId) {
      return;
    }

    dispatch(
      setIsLoadingModal({
        active: true,
        isMakingApiCall: true,
      }),
    );

    const mode = id ? FormMode.UPDATE : FormMode.CREATE;

    const isCreation = mode === FormMode.CREATE;

    const navigateToCustomersList = () =>
      router.navigate(getPathWithOrgData('/customers'));

    const customerId = Number(id);
    let customerObject = isCreation
      ? CUSTOMER_INITIAL_STATE
      : selectCustomers(state)[customerId];
    const customersVersion = getCustomersVersionWithStateCore(state) || 0;

    if (!customerObject) {
      const { success } = await dispatch(fetchSingleCustomerWeb(customerId));
      if (!success) {
        return navigateToCustomersList();
      }

      customerObject = selectCustomers(getState())[customerId];

      if (!customerObject) {
        return navigateToCustomersList();
      }
    }

    dispatch(
      setCustomersChangeData({
        id: customerId,
        data: cloneDeep(customerObject),
        originalData: cloneDeep(customerObject),
        hasChanges: false,
        differences: [],
        finalDiffObject: null,
        errors: {},
        sectionId: CustomersSectionId.ALL,
        customersVersion,
        selectedLocations: [],
        mode,
      }),
    );

    dispatch(
      setIsLoadingModal({
        active: false,
        isMakingApiCall: false,
      }),
    );
  };

export const fetchCustomersWeb =
  (
    page: number,
    append: boolean,
    search?: string,
  ): AppThunk<
    Promise<{
      success: boolean;
      totalPages: number;
      totalCustomers: number;
      currentPage: number;
    }>
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);
    return dispatch(
      actionCreatorsCustomers.fetchCustomers(
        organizationId,
        currentLocationId,
        currentLocationId,
        licenseId,
        page,
        append,
        search,
      ),
    );
  };

export const fetchSingleCustomerWeb =
  (
    customerId: number,
  ): AppThunk<
    Promise<{
      success: boolean;
    }>
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const { organizationId, currentLocationId, licenseId } =
      getRequiredActionsData(state);
    return dispatch(
      actionCreatorsCustomers.fetchSingleCustomer(
        organizationId,
        currentLocationId,
        currentLocationId,
        licenseId,
        customerId,
      ),
    );
  };

export const createCustomerPostRequestWeb =
  (navigateCallback: NavigateFunction, isDelete = false) =>
  (navigate = true, mode?: FormMode): AppThunk<void> => {
    return dispatch => {
      if (navigate && (mode === FormMode.CREATE || isDelete)) {
        navigateCallback('/customers', {
          state: { omitBlocker: true },
        });

        dispatch(resetCustomersChangeData());
      }

      dispatch(updateCustomerChangeData() as unknown as AppThunk<void>);

      dispatch(setIsLoadingModal({ active: false }));
    };
  };

export const checkForCustomerChangesAndNavigateWeb = (
  onContinue: () => void,
  onRefresh: () => void,
  navigateCallback: NavigateFunction,
  onContinueResetChangeData = true,
  isSavePressed = false,
  navigateOnSave = false,
): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();

    const currentLocationId = state.app.locationId;
    const organizationId = state.app.organizationId;
    const currentDeviceLicenseSettingsId = 0;

    return dispatch(
      checkForCustomerChangesAndNavigateCore(
        onContinue,
        onRefresh,
        createCustomerPostRequestWeb(navigateCallback) as any,
        organizationId,
        currentLocationId,
        null,
        currentDeviceLicenseSettingsId,
        webErrorCodesDispatcher,
        undefined,
        onContinueResetChangeData,
        isSavePressed,
        navigateOnSave,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const saveCustomerChangesOnSaveClickWeb = (
  onRefresh: () => void,
  onContinue: () => void,
  navigateCallback: NavigateFunction,
): AppThunk<void> => {
  return dispatch => {
    const onIsValid = () => {
      dispatch(
        checkForCustomerChangesAndNavigateWeb(
          () => onContinue && onContinue(),
          onRefresh,
          navigateCallback,
          true,
          true,
          true,
        ),
      );
    };

    return dispatch(
      saveCustomerChangesOnSavePressCore(
        onIsValid,
      ) as unknown as AppThunk<void>,
    );
  };
};

export const cancelCustomerChangesOnCancelPressWeb = (
  navigate: NavigateFunction,
  customOnSuccess?: () => void,
): AppThunk<void> => {
  return dispatch =>
    dispatch(
      cancelCustomerChangesOnCancelPressCore(
        () => navigate(getPathWithOrgData('/customers')),
        customOnSuccess,
      ) as unknown as AppThunk<void>,
    );
};
