import Box from '@app/components/common/Box';
import Collapsible from '@app/components/common/List/Collapsible';
import ListItem from '@app/components/common/List/ListItem';
import { ITab } from '@app/components/common/Tabs/types';
import {
  resetPathsByBucketNavigation,
  resetPathsByMainPath,
} from '@app/helpers/restoration';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import { SETTINGS_URLS } from '@app/router/types';
import { actionCreatorsSettingsWeb } from '@app/state';
import { bindActionCreators } from '@reduxjs/toolkit';
import { ParseKeys } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TCollapsibleListsSettings,
  TCollapsibleListsSettingsState,
} from '../types';
import { getPathWithOrgData } from '@app/helpers/navigation';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { BUCKET_PATH_INDEX, MODULE_PATH_INDEX } from '@app/constants';

const TABS_DATA: ITab[] = [
  {
    id: 1,
    key: 'general',
    name: 'settingsModule.tabs.general.general',
    subTabs: [
      {
        id: 11,
        key: 'general-settings',
        name: 'settingsModule.generalSettings.title',
      },
      {
        id: 12,
        key: 'online-ordering',
        name: 'settingsModule.tabs.general.onlineOrdering',
      },
    ],
  },
  {
    id: 2,
    key: 'accounts',
    name: 'settingsModule.tabs.accounts.accounts',
    subTabs: [
      {
        id: 21,
        key: 'users',
        name: 'settingsModule.tabs.accounts.users',
      },
      {
        id: 22,
        key: 'roles',
        name: 'settingsModule.tabs.accounts.roles',
      },
    ],
  },
  {
    id: 3,
    key: 'printing',
    name: 'settingsModule.tabs.printing.printing',
    subTabs: [
      {
        id: 31,
        key: 'prep-stations',
        name: 'settingsModule.tabs.printing.prepStations',
      },
      {
        id: 32,
        key: 'printing-rules',
        name: 'settingsModule.tabs.printing.printingRules',
      },
      {
        id: 33,
        key: 'ticket',
        name: 'settingsModule.tabs.printing.ticket',
        subTabs: [
          {
            id: 331,
            key: 'register-ticket',
            name: 'settingsModule.tabs.printing.registerTicket',
          },
          {
            id: 332,
            key: 'kitchen-ticket',
            name: 'settingsModule.tabs.printing.kitchenTicket',
          },
          {
            id: 334,
            key: 'guest-check',
            name: 'settingsModule.guestCheckSettings.title',
          },
          {
            id: 333,
            key: 'guest-receipt',
            name: 'settingsModule.tabs.printing.guestReceipt',
          },
        ],
      },
    ],
  },
  // {
  //   id: 4,
  //   key: 'management',
  //   name: 'settingsModule.tabs.management',
  // },
  {
    id: 5,
    key: 'lockScreen',
    name: 'settingsModule.tabs.lockScreen',
  },
  // {
  //   id: 6,
  //   key: 'homeScreen',
  //   name: 'settingsModule.tabs.homeScreen',
  // },
  {
    id: 7,
    key: 'register',
    name: 'settingsModule.tabs.register.register',
  },
  { id: 8, key: 'payments', name: 'settingsModule.tabs.payments' },
  { id: 9, key: 'taxes', name: 'settingsModule.tabs.taxes' },
];

const SettingsTabs = () => {
  // Redux
  const dispatch = useDispatch();
  const checkForSettingsChangesAndNavigateWeb = bindActionCreators(
    actionCreatorsSettingsWeb.checkForSettingsChangesAndNavigateWeb,
    dispatch,
  );

  // Local state
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigateWithOrg();

  const [collapsibleItems, setCollapsibleItems] =
    useState<TCollapsibleListsSettingsState>({
      general: false,
      accounts: false,
      printing: false,
      ticket: false,
    });

  const [activeTab, setSelectedTab] = useState<
    { tabId: number; subTabId: number | null } | undefined
  >();

  const getNavigationPath = (tabId: number, subTabId: number | null) => {
    return `settings/${
      subTabId
        ? SETTINGS_URLS[subTabId.toString() as keyof typeof SETTINGS_URLS]
        : SETTINGS_URLS[tabId.toString() as keyof typeof SETTINGS_URLS]
    }`;
  };

  const currentPath = location.state?.currentPath || location.pathname;

  const handleSelectCurrentTab = (path: string) => {
    let pathIndex = Object.entries(SETTINGS_URLS).find(
      ([, pathName]) => pathName === path,
    )?.[0];
    if (isNaN(Number(pathIndex))) {
      pathIndex = pathIndex = Object.entries(SETTINGS_URLS).find(
        ([, pathName]) =>
          pathName === path.split('/')[0] + '/' + path.split('/')[1],
      )?.[0];
    }

    if (path.split('/').length > 1) {
      const mainPath =
        Object.values(SETTINGS_URLS).indexOf(path.split('/')[0]) + 1;
      setSelectedTab({ tabId: mainPath, subTabId: Number(pathIndex) });
      const keyToNavigate = path.split('/')[0];
      handleCloseTheOtherCollapsibleItems(keyToNavigate);
    } else {
      setSelectedTab({ tabId: Number(pathIndex), subTabId: null });
      handleCloseTheOtherCollapsibleItems(path);
    }
  };

  useEffect(() => {
    const path = location.pathname.replace(
      getPathWithOrgData('/settings/'),
      '',
    );

    handleSelectCurrentTab(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleOnSelectedTabChange = (
    tabId: number,
    subTabId: number | null,
    isSubSubTab: boolean,
    openInNewTab?: boolean,
  ) => {
    checkForSettingsChangesAndNavigateWeb(
      () => {
        const newPath = getPathWithOrgData(getNavigationPath(tabId, subTabId));
        if (openInNewTab) return window.open(newPath, '_blank');

        setSelectedTab({ tabId, subTabId });
        const _currentPath = currentPath;

        if (currentPath !== newPath) {
          navigate(newPath);

          const currentModule = _currentPath.split('/')[MODULE_PATH_INDEX];
          resetPathsByBucketNavigation(
            currentModule,
            newPath.split('/')[BUCKET_PATH_INDEX],
            newPath.split('/')[5],
          );

          if (currentModule && newPath.includes(currentModule)) return;

          let keyToNavigate = newPath.split('/')[BUCKET_PATH_INDEX];
          if (isSubSubTab) {
            keyToNavigate = newPath.split('/')[5];
          }

          handleCloseTheOtherCollapsibleItems(keyToNavigate);
          resetPathsByMainPath('/settings/', newPath);
        } else {
          handleChangeCollapsibleItems(newPath as TCollapsibleListsSettings);
        }
      },
      useRefreshSettingsWeb,
      () => null,
    );
  };

  const handleChangeCollapsibleItems = (
    key: TCollapsibleListsSettings,
    forceToggle?: boolean,
  ) => {
    if ((key in collapsibleItems && !collapsibleItems[key]) || forceToggle)
      setCollapsibleItems(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleCloseTheOtherCollapsibleItems = (keyToNavigate: string) => {
    const newCollapsibleItems = Object.keys(collapsibleItems).reduce(
      (acc, key) => {
        if (key === keyToNavigate) {
          acc[key as TCollapsibleListsSettings] = true;
        } else {
          acc[key as TCollapsibleListsSettings] = false;
        }
        return acc;
      },
      {} as TCollapsibleListsSettingsState,
    );
    setCollapsibleItems(newCollapsibleItems);
  };

  return (
    <>
      {TABS_DATA.map(tab =>
        tab.subTabs ? (
          <Box key={tab.id} csx={{ marginLeft: '15px' }}>
            <Collapsible
              collapsibleKey={tab.key}
              csx={{
                gap: '5px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '5px',
                paddingLeft: '15px',
              }}
              isExpanded={
                collapsibleItems[tab.key as TCollapsibleListsSettings]
              }
              listItemProps={{
                isActive: activeTab?.tabId === tab.id,
                text: t(tab.name as ParseKeys),
                onClick: openInNewTab => {
                  if (tab.subTabs)
                    handleOnSelectedTabChange(
                      tab.id,
                      tab.subTabs[0].id,
                      false,
                      openInNewTab,
                    );
                },
                onIconRightClick: () => {
                  handleChangeCollapsibleItems(
                    tab.key as TCollapsibleListsSettings,
                    true,
                  );
                },
                isLink: getPathWithOrgData(
                  getNavigationPath(tab.id, tab.subTabs[0].id),
                ),
              }}>
              {tab.subTabs.map(subTab =>
                subTab.subTabs ? (
                  <Collapsible
                    collapsibleKey={subTab.key}
                    csx={{
                      gap: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                      paddingLeft: '15px',
                    }}
                    key={tab.id}
                    isExpanded={
                      activeTab?.subTabId
                        ?.toString()
                        .includes(subTab.id.toString()) ||
                      collapsibleItems[subTab.key as TCollapsibleListsSettings]
                    }
                    listItemProps={{
                      isActive: activeTab?.subTabId
                        ?.toString()
                        .includes(subTab.id.toString()),
                      text: t(subTab.name as ParseKeys),
                      onClick: openInNewTab => {
                        if (subTab.subTabs)
                          handleOnSelectedTabChange(
                            tab.id,
                            subTab.subTabs[0].id,
                            true,
                            openInNewTab,
                          );
                      },
                      onIconRightClick: () => {
                        if (
                          activeTab?.subTabId
                            ?.toString()
                            .includes(subTab.id.toString())
                        ) {
                          setSelectedTab({
                            tabId: tab.id,
                            subTabId: null,
                          });
                        } else {
                          const currentSubTabKey = currentPath
                            .split('/')
                            .at(-1) as string;

                          if (subTab?.subTabs) {
                            const subSubTabId = subTab.subTabs.find(
                              _subTab => _subTab.key === currentSubTabKey,
                            )?.id;

                            if (subSubTabId) {
                              setSelectedTab({
                                tabId: tab.id,
                                subTabId: subSubTabId,
                              });
                            } else {
                              handleChangeCollapsibleItems(
                                subTab.key as TCollapsibleListsSettings,
                                true,
                              );
                            }
                          }
                        }
                      },
                      isLink: getPathWithOrgData(
                        getNavigationPath(tab.id, subTab.subTabs[0].id),
                      ),
                    }}>
                    {subTab.subTabs.map(subSubTab => (
                      <ListItem
                        key={`${tab.id}_subTab_${subTab.id}_subTab_${subSubTab.id}`}
                        isSubItem
                        isActive={activeTab?.subTabId === subSubTab.id}
                        text={t(subSubTab.name as ParseKeys)}
                        onClick={openInNewTab =>
                          handleOnSelectedTabChange(
                            tab.id,
                            subSubTab.id,
                            false,
                            openInNewTab,
                          )
                        }
                        isLink={getPathWithOrgData(
                          getNavigationPath(tab.id, subSubTab.id),
                        )}
                      />
                    ))}
                  </Collapsible>
                ) : (
                  <ListItem
                    key={`${tab.id}_subTab_${subTab.id}`}
                    isSubItem
                    isActive={activeTab?.subTabId === subTab.id}
                    text={t(subTab.name as ParseKeys)}
                    onClick={openInNewTab =>
                      handleOnSelectedTabChange(
                        tab.id,
                        subTab.id,
                        false,
                        openInNewTab,
                      )
                    }
                    isLink={getPathWithOrgData(
                      getNavigationPath(tab.id, subTab.id),
                    )}
                  />
                ),
              )}
            </Collapsible>
          </Box>
        ) : (
          <Box key={tab.id} csx={{ paddingLeft: '15px' }}>
            <ListItem
              isSubItem
              isActive={activeTab?.tabId === tab.id}
              text={t(tab.name as ParseKeys)}
              onClick={openInNewTab =>
                handleOnSelectedTabChange(tab.id, null, false, openInNewTab)
              }
              isLink={getPathWithOrgData(getNavigationPath(tab.id, null))}
            />
          </Box>
        ),
      )}
    </>
  );
};

export default SettingsTabs;
