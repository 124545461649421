import {
  commonSubsectionStyles,
  previewSubsectionStyles,
  registerDisplaySubsectionStyles,
} from '@app/components/LoggedIn/Menu/Categories/CategoriesDetails/BasicSection/styles';
import ButtonCategory from '@app/components/LoggedIn/Register/CategoriesBar/ButtonCategory';
import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import Switch from '@app/components/common/Switch';
import Typography from '@app/components/common/Typography';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import {
  makeSelectItemData,
  selectIsMenuCreationMode,
  selectIsMenuMasterMode,
} from '@app/state/menu/menuSelectors';
import {
  checkForChangesAndNavigateWeb as checkForChangesAndNavigateWebAction,
  deleteBucketElementWeb as deleteBucketElementWebAction,
} from '@app/state/menu/menuWebActions';
import { store } from '@app/state/store';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  ICategory,
  SectionId,
  actionCreatorsApp,
  actionCreatorsMenu,
  useFormError,
} from '@westondev/tableturn-core';
import { useCallback, useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuCloneModal from '../../../MenuCloneModal';
import { customizerSubsectionStyles } from './styles';
import BgAndTextColorPickers from '../../../common/BgAndTextColorPickers';
import { actionCreatorsMenuWeb } from '@app/state';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import Card from '@app/components/common/Card';
import { labelStyles } from '@app/components/common/Input/styles';
import ImageUploader from '@app/components/common/ImageUploader';

const SECTION_ID = SectionId.BASIC;

const getMemoizedItemData = makeSelectItemData('subcategories', SECTION_ID);

const BasicSection = ({ t, ...props }: IAccordionSection & WithTranslation) => {
  const navigate = useNavigateWithOrg();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { updateSubcategory: setValue } = bindActionCreators(
    actionCreatorsMenu,
    dispatch,
  );

  const addBreadcrumbLocationWeb = bindActionCreators(
    actionCreatorsMenuWeb.addBreadcrumbLocationWeb,
    dispatch,
  );

  const { setShowConfirmationModal, showToast } = bindActionCreators(
    actionCreatorsApp,
    dispatch,
  );
  const checkForChangesAndNavigateWeb = bindActionCreators(
    checkForChangesAndNavigateWebAction,
    dispatch,
  );
  const deleteBucketElementWeb = bindActionCreators(
    deleteBucketElementWebAction,
    dispatch,
  );
  const onRefresh = useRefreshMenuWeb();

  const { itemData } = useSelector(getMemoizedItemData);

  const isMasterMode = useSelector(selectIsMenuMasterMode);
  const isCreation = useSelector(selectIsMenuCreationMode);

  const [previewActive, setPreviewActive] = useState(false);
  const [isCloneModalActive, setIsCloneModalActive] = useState(false);

  const activeSwitch = (
    <Switch
      checked={itemData ? itemData.active : false}
      onChange={active => setValue({ active })}
      showDefaultLabel
    />
  );

  const backgroundColor =
    itemData?.backgroundColor || theme.colors.semanticBlue;

  const textColor = itemData?.textColor || theme.colors.textWhite;

  const onReset = () => {
    setValue({ backgroundColor: null, textColor: null });
  };

  const onResetBackground = () => {
    setValue({ backgroundColor: null });
  };

  const onResetText = () => {
    setValue({ textColor: null });
  };

  const assignedItemIds: number[] = itemData?.itemIds;
  const assignedComboIds: number[] = itemData?.comboIds;

  const nameError = useFormError('name');
  const posNameError = useFormError('posName');

  const handleDelete = useCallback(() => {
    if (assignedItemIds.length || assignedComboIds.length) {
      setShowConfirmationModal({
        active: true,
        title: t('menuScreen.subcategoryDetails.basic.deleteModal.title'),
        type: 'warning',
        description: t('menuScreen.subcategoryDetails.basic.deleteModal.body'),
        hideSuccess: true,
        onSuccess: () => null,
      });
      return;
    }
    setShowConfirmationModal({
      active: true,
      title: t('menuScreen.common.bucket.subcategories.singular'),
      type: 'delete',
      name: itemData?.name,
      onSuccess: () => {
        deleteBucketElementWeb();
      },
    });
  }, [
    assignedComboIds.length,
    assignedItemIds.length,
    deleteBucketElementWeb,
    itemData?.name,
    setShowConfirmationModal,
    t,
  ]);

  const options = useMemo(() => {
    if (isCreation) {
      return undefined;
    }

    return [
      {
        text: t('commonButtons.clone'),
        handler: () => setIsCloneModalActive(true),
        isDisabled: false,
      },
      {
        text: t('commonButtons.delete'),
        handler: handleDelete,
        isDisabled: !isMasterMode,
      },
    ];
  }, [handleDelete, isCreation, isMasterMode, t]);

  const hasSectionError =
    Boolean(nameError?.error) || Boolean(posNameError?.error);

  return (
    <>
      <MenuCloneModal
        active={isCloneModalActive}
        bucket="subcategories"
        onCancel={() => setIsCloneModalActive(false)}
        onClose={() => setIsCloneModalActive(false)}
        onClone={newId => {
          const currentMenu = store.getState().menu.selectedMode.currentMenu;
          checkForChangesAndNavigateWeb(() => {
            const _navigate = () => navigate(`/menu/subcategories/${newId}`);
            addBreadcrumbLocationWeb({
              action: BreadCrumbAction.REPLACE,
              text: currentMenu.subcategories[newId]?.name,
              onPress: _navigate,
              pathURL: `/menu/subcategories/${newId}`,
            });
            _navigate();
          }, onRefresh);
        }}
        name={itemData?.name || ''}
      />
      <AccordionSection
        title={t('menuScreen.subcategoryDetails.basic.title')}
        info={t('menuScreen.subcategoryDetails.basic.toolTip')}
        required
        hasError={hasSectionError}
        titleTextContainerCsx={{ minWidth: '150px' }}
        collapsedHeaderComponent={
          <Box
            csx={[
              {
                display: 'flex',
                height: '100%',
                gap: '10px',
              },
            ]}>
            <Divider direction="vertical" lineCsx={{ borderColor: 'red' }} />
            {activeSwitch}
          </Box>
        }
        options={options}
        optionsDisabled={isCreation}
        onOptionsClickDisabled={() =>
          showToast({
            type: 'info',
            title: t('menuScreen.categoryMain.disabledActionToast.title'),
            description: isCreation
              ? t('menuScreen.categoryDetails.basic.optionsDisabled.creation')
              : t('menuScreen.categoryDetails.basic.optionsDisabled.location'),
          })
        }
        {...props}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}>
          {activeSwitch}

          {/* Names Subsection */}
          <Card.SubCard title={t('menuScreen.itemDetails.basic.namesTitle')}>
            <Grid
              rowGap={20}
              columnGap={20}
              columns={15}
              csx={{ alignItems: 'flex-start' }}>
              <Grid.Item
                mb={12}
                sm={6}
                md={6}
                lg={3}
                csx={displayFlexEndColumnStyles}>
                <Input
                  label={t('menuScreen.subcategoryDetails.basic.name')}
                  placeholder={t('menuScreen.subcategoryDetails.basic.name')}
                  required
                  caption={nameError?.error}
                  error={Boolean(nameError?.error)}
                  value={itemData?.name || ''}
                  disabled={!isMasterMode && !isCreation}
                  contentEditable={isMasterMode || isCreation}
                  onChange={name => {
                    setValue({
                      name: name.target.value,
                    });

                    if (
                      itemData.posName === '' ||
                      itemData.posName === itemData.name
                    ) {
                      setValue({
                        posName: name.target.value,
                      });
                    }

                    if (itemData.posName === '' && posNameError) {
                      posNameError.clear();
                    }
                  }}
                  onFocus={() => nameError && nameError.clear()}
                />
              </Grid.Item>
              <Grid.Item
                mb={12}
                sm={6}
                md={6}
                lg={3}
                csx={displayFlexEndColumnStyles}>
                <Input
                  label={t('menuScreen.subcategoryDetails.basic.posName')}
                  placeholder={t('menuScreen.subcategoryDetails.basic.posName')}
                  required
                  caption={posNameError?.error}
                  error={Boolean(posNameError?.error)}
                  value={itemData?.posName || ''}
                  onChange={posName =>
                    setValue({
                      posName: posName.target.value,
                    })
                  }
                  onFocus={() => posNameError && posNameError.clear()}
                />
              </Grid.Item>
            </Grid>
          </Card.SubCard>

          {/* <Card.SubCard title="More Information">
            <Grid rowGap={15} columnGap={15}>
              <Grid.Item mb={9} xl={11}>
                <TextAreaInput
                  label={t(
                    'menuScreen.itemDetails.moreInformation.inputs.description.label',
                  )}
                  maxLength={1000}
                  value={itemData?.description || ''}
                  onChange={e => {
                    setValue({
                      description: e.currentTarget.value,
                    });
                  }}
                  placeholder={t(
                    'menuScreen.itemDetails.moreInformation.inputs.description.placeholder',
                  )}
                />
              </Grid.Item>
              <Grid.Item
                mb={3}
                xl={1}
                csx={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}>
                <Grid csx={{ width: '100%' }} rowGap={10}>
                  <Grid.Item>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setValue({
                          description: '',
                        });
                      }}
                      disabled={itemData?.description === ''}
                      csx={{
                        height: '90px',
                      }}>
                      {t('commonButtons.clear')}
                    </Button>
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            </Grid>
          </Card.SubCard> */}

          {/* Register Display Subsection */}
          <Card.SubCard
            title={t(
              'menuScreen.itemDetails.registerDisplay.registerDisplayOptions.title',
            )}
            csx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
            <Grid
              rowGap={20}
              columnGap={20}
              columns={{
                mb: 12,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 15,
              }}
              csx={{
                paddingBottom: '20px',
                borderBottom: `1px solid ${theme.colors.lightGrey}`,
              }}>
              <Grid.Item
                sm={6}
                md={4}
                xl={3}
                csx={{ justifyContent: 'flex-end' }}>
                {/* <ToolTip
          showHover={showHover}
          content={t('menuScreen.itemDetails.basic.itemImageInfo')}>
          <div />
        </ToolTip> */}
                <Typography
                  csx={labelStyles}
                  // onMouseOver={handlePopUp}
                  // onMouseOut={() => {
                  //   if (newTimeout) clearTimeout(newTimeout);
                  //   setShowHover(false);
                  // }}
                >
                  Upload Image
                </Typography>
                <Box
                  csx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}>
                  <ImageUploader
                    csx={{ marginTop: '0' }}
                    onChange={(imageUrl: string | null) => {
                      setValue({ imageUrl });
                    }}
                    source={itemData?.imageUrl || undefined}
                    modalProps={{
                      title: t('menuScreen.itemDetails.basic.itemImageLabel'),
                      subtitle: t(
                        'menuScreen.itemDetails.basic.massUpdateImageSubtitle',
                      ),
                    }}
                    uploadButtonText={undefined}
                    deleteButtonText={undefined}
                  />
                </Box>
              </Grid.Item>
            </Grid>

            <Grid rowGap={20} columnGap={20} csx={{ alignItems: 'flex-start' }}>
              <Grid.Item
                lg={6}
                xl={4}
                csx={[commonSubsectionStyles, registerDisplaySubsectionStyles]}>
                <BgAndTextColorPickers
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  onBackgroundColorChange={color =>
                    setValue({ backgroundColor: color })
                  }
                  onTextColorChange={color => setValue({ textColor: color })}
                  onReset={onReset}
                  onResetBackground={onResetBackground}
                  onResetText={onResetText}
                />
              </Grid.Item>
              <Grid.Item
                lg={6}
                xl={4}
                csx={[commonSubsectionStyles, previewSubsectionStyles]}>
                <Typography
                  color="persistentSemanticBlue"
                  fontWeight="medium"
                  csx={{ marginBottom: '25px' }}>
                  {t('menuScreen.categoryDetails.basic.previewTitle')}
                </Typography>
                <Box
                  csx={{
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ButtonCategory
                    isActive={previewActive}
                    isLastElement
                    onClick={() => setPreviewActive(prev => !prev)}
                    category={
                      {
                        name:
                          itemData?.posName ||
                          t('loggedIn.commonFields.basic.posName'),
                        backgroundColor: backgroundColor,
                        textColor: textColor,
                      } as ICategory
                    }
                  />
                </Box>
              </Grid.Item>
              <Grid.Item
                xl={4}
                csx={[commonSubsectionStyles, customizerSubsectionStyles]}>
                <Checkbox
                  label={t('menuScreen.subcategoryDetails.basic.customizer')}
                  info={t(
                    'menuScreen.subcategoryDetails.basic.customizertoolTip',
                  )}
                  checked={itemData?.customizer}
                  onChange={customizer => setValue({ customizer })}
                />
              </Grid.Item>
            </Grid>
          </Card.SubCard>
        </Box>
      </AccordionSection>
    </>
  );
};

export default BasicSection;
