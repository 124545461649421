import SubcategoryDropdownSection from '@app/components/LoggedIn/Menu/Items/ItemDetails/BasicSection/CategoriesSubsection/SubcategoryDropdownSection';
import some from 'lodash/some';
import { useEffect, useMemo, useState } from 'react';
import Box from '../../Box';
import Button from '../../Button';
import Card from '../../Card';
import DropdownItemBadge from '../../Dropdown/MultiSelectSearchDropdown/DropdownItemBadge';
import Modal from '../../Modal';
import NoElementsFound from '../../NoElementsFound';
import Typography from '../../Typography';
import { TFilterGenericSelectionModal } from '../FilterGenericSelectionModal/types';
import { MenuTypeStatus } from '../MenuTypesSelectionModal/Filter/types';
import ScrollCardsSection from './ScrollCardsSection';
import { genericSelectionModalStyles } from './styles';
import { IGenericSelectionModal, IItemSelection, emptyButton } from './types';

const GenericSelectionModal = <T,>({
  t,
  active = false,
  onModalClose,
  children,
  onPress,
  title,
  buttons,
  selectedButtonIds,
  selectedSectionTitle,
  onAssociate,
  emptySectionMessage,
  noCardSelectedMessage,
  noAvailableCardsMessage,
  filterInfo,
  currentButtonInfo,
  allowAssociateMultiple: _allowAssociateMultiple = true,
  showDescriptionPanel,
  btnSuccessText,
  showSelectedButtons,
  showOnly,
  associateOnSelect,
  selectedMenuTypeIds,
  customOnDelete,
  filterSelectedItems: _filterSelectedItems,
  showSelectAll,
  filterValue,
  filterOptions,
  showCommonFilter,
  onFilter,
  onFilterClear,
  onFilterClearAll,
  isFilterClearDisabled,
  isFilterClearAllDisabled,
  isFilterActive,
  defaultFilterValue,
  groupByMenuType,
  showIsLoadingOnPress,
  preSelectedItem,
  onIsLink,
  showSubcategoryDropdown,
  btnCancelText,
  buttonsContainerCsx,
}: IGenericSelectionModal<T>) => {
  const [selectedItems, setSelectedItems] = useState<IItemSelection[]>([]);
  const [itemPressed, setItemPressed] = useState<IItemSelection>(emptyButton);
  // FIXME: Add loading layer
  const [, setIsLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState([
    defaultFilterValue || MenuTypeStatus.all,
  ]);

  useEffect(() => {
    if (preSelectedItem?.id && itemPressed.id === emptyButton.id) {
      setItemPressed(preSelectedItem);
    }
  }, [preSelectedItem, itemPressed.id]);

  const allowAssociateMultiple = associateOnSelect
    ? false
    : _allowAssociateMultiple;

  const filterSelectedItems =
    _filterSelectedItems !== undefined
      ? _filterSelectedItems
      : allowAssociateMultiple;

  const handleItemSelection = (extraInfo?: Partial<IItemSelection>) => {
    const button = itemPressed;
    const { id } = button;
    const isPressed = some(selectedItems, ['id', id]);

    let newSelectedItems;

    if (isPressed) {
      newSelectedItems = selectedItems.filter(item => item.id !== id);
    } else {
      newSelectedItems = selectedItems.concat({ ...button, ...extraInfo });
    }

    setSelectedItems(newSelectedItems);
    handleOnClick(emptyButton, false);
  };

  const handleOnButtonPress = (
    button: IItemSelection,
    isSelected: boolean,
    openInNewTab?: boolean,
  ) => {
    if (showOnly) return;
    if (isSelected && !showDescriptionPanel) {
      return;
    }

    if (associateOnSelect || selectedMenuTypeIds) {
      return onAssociate([button], onIsLink ? openInNewTab : false);
    }
    setItemPressed(prev =>
      prev?.id === button.id &&
      (groupByMenuType
        ? prev?.menuTypeId?.[0] === button.menuTypeId?.[0]
        : true)
        ? emptyButton
        : button,
    );
    onPress && onPress(button, isSelected);

    if (!allowAssociateMultiple) {
      const isItemIncluded = selectedItems.some(
        selectedItem => selectedItem.id === button.id,
      );
      setSelectedItems(isItemIncluded || button.id === -1 ? [] : [button]);
    } else if (!showDescriptionPanel) {
      const isItemIncluded = selectedItems.some(
        selectedItem =>
          selectedItem.id === button.id &&
          (groupByMenuType
            ? selectedItem.menuTypeId?.[0] === button.menuTypeId?.[0]
            : true),
      );

      const filteredSelectedItems = isItemIncluded
        ? selectedItems.filter(
            item =>
              !(
                item.id === button.id &&
                (groupByMenuType
                  ? item.menuTypeId?.[0] === button.menuTypeId?.[0]
                  : true)
              ),
          )
        : [...selectedItems, button];

      setSelectedItems(filteredSelectedItems);
    }
  };

  const renderLoading = (callback: () => void) => {
    if (!showIsLoadingOnPress) {
      return callback();
    }
    setIsLoading(true);
    requestAnimationFrame(() => {
      callback();
      setIsLoading(false);
    });
  };

  const handleOnClick = (
    button: IItemSelection,
    isSelected: boolean,
    openInNewTab?: boolean,
  ) => {
    renderLoading(() => {
      handleOnButtonPress(button, isSelected, openInNewTab);
    });
  };

  const handleOnDelete = (itemToDelete: IItemSelection) => {
    renderLoading(() => {
      const newButtons = selectedItems.filter(
        item =>
          !(
            item.id === itemToDelete.id &&
            (groupByMenuType
              ? item.menuTypeId?.[0] === itemToDelete.menuTypeId?.[0]
              : true)
          ),
      );
      customOnDelete && customOnDelete(itemToDelete.id);
      setSelectedItems(newButtons);
    });
  };

  useEffect(() => {
    if (selectedMenuTypeIds) {
      const newSelectedItems = buttons.filter(item =>
        selectedMenuTypeIds.includes(Number(item.id)),
      );

      setSelectedItems(newSelectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons]);

  useEffect(() => {
    if (selectedButtonIds) {
      const newSelectedItems = buttons.filter(item =>
        selectedButtonIds.includes(Number(item.id)),
      );

      setSelectedItems(newSelectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButtonIds]);

  useEffect(() => {
    if (currentButtonInfo) {
      const newSelectedItems = selectedItems.map(item => {
        if (item.id !== currentButtonInfo.id) {
          return item;
        }

        return {
          ...item,
          ...currentButtonInfo,
        };
      });

      setSelectedItems(newSelectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentButtonInfo]);

  const defaultFilterOptions = useMemo(
    () =>
      showCommonFilter
        ? [
            {
              id: 1,
              label: t('settingsModule.prepStationsSettings.general.status'),
              options: [
                { label: t('commonTexts.all'), value: MenuTypeStatus.all },
                {
                  label: t('commonTexts.active'),
                  value: MenuTypeStatus.active,
                },
                {
                  label: t('commonTexts.inactive'),
                  value: MenuTypeStatus.inactive,
                },
              ],
            },
          ]
        : undefined,
    [showCommonFilter, t],
  );

  const defaultOnFilter = (value: MenuTypeStatus) => {
    if (defaultFilter.includes(value)) {
      return setDefaultFilter([MenuTypeStatus.all]);
    }
    setDefaultFilter([value]);
  };

  const filteredButtons = useMemo(
    () =>
      showCommonFilter
        ? defaultFilter.includes(MenuTypeStatus.all)
          ? buttons
          : buttons.filter(
              button =>
                button.isActive ===
                defaultFilter.includes(MenuTypeStatus.active),
            )
        : buttons,
    [defaultFilter, buttons, showCommonFilter],
  );

  const defaultOnFilterClear = () => {
    setDefaultFilter([MenuTypeStatus.all]);
  };

  const defaultFilterText = useMemo(
    () =>
      defaultFilterOptions?.[0].options.find(
        option => option.value === defaultFilter[0],
      )?.label || '',
    [defaultFilter, defaultFilterOptions],
  );

  const isDefaultFilterActive = !defaultFilter.includes(MenuTypeStatus.all);

  return (
    <Modal
      isActive={active}
      onModalClose={onModalClose}
      size={
        showOnly
          ? 1000
          : showDescriptionPanel || showSelectedButtons
          ? 1000
          : 700
      }
      height={showSelectedButtons || showDescriptionPanel ? 620 : 'auto'}
      title={title}
      modalContainerCsx={genericSelectionModalStyles(showDescriptionPanel)}
      noPadding
      footer={
        <>
          <Button csx={{ width: '100px' }} onClick={onModalClose}>
            {btnCancelText ||
              (showOnly || selectedMenuTypeIds
                ? t('commonButtons.close')
                : t('commonButtons.cancel'))}
          </Button>
          {!showOnly && !associateOnSelect && !selectedMenuTypeIds && (
            <Button
              variant="primary"
              csx={{ width: '100px' }}
              disabled={selectedItems.length === 0}
              onClick={() => onAssociate(selectedItems)}>
              {btnSuccessText ? btnSuccessText : t('commonButtons.associate')}
            </Button>
          )}
        </>
      }>
      <Box className="optionsContainer">
        <ScrollCardsSection
          items={filteredButtons}
          itemPressed={itemPressed.id}
          onClick={(button, openInNewTab) =>
            handleOnClick(button, false, openInNewTab)
          }
          onIsLink={onIsLink}
          selectedItems={selectedItems}
          noAvailableCardsMessage={noAvailableCardsMessage}
          showSelectAll={showSelectAll}
          onFilterClearAll={onFilterClearAll}
          filterSelectedItems={filterSelectedItems}
          groupByMenuType={groupByMenuType}
          isFilterClearAllDisabled={isFilterClearAllDisabled}
          onSelect={selectedButtons => setSelectedItems(selectedButtons)}
          filterInfo={
            showCommonFilter ? (
              <Box
                csx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                {isDefaultFilterActive && (
                  <DropdownItemBadge
                    key={defaultFilter[0]}
                    text={defaultFilterText}
                    onClick={() => {
                      setDefaultFilter([MenuTypeStatus.all]);
                    }}
                  />
                )}
              </Box>
            ) : (
              filterInfo
            )
          }
          filterValue={
            filterValue
              ? filterValue
              : showCommonFilter
              ? [{ id: 1, values: defaultFilter as T[] }]
              : undefined
          }
          isFilterActive={
            showCommonFilter ? isDefaultFilterActive : isFilterActive
          }
          onFilter={
            onFilter
              ? (onFilter as (option: unknown, id: number) => void)
              : showCommonFilter
              ? (((newValue: T) =>
                  defaultOnFilter(newValue as MenuTypeStatus)) as (
                  option: unknown,
                  id: number,
                ) => void)
              : undefined
          }
          filterOptions={
            filterOptions
              ? filterOptions
              : (defaultFilterOptions as TFilterGenericSelectionModal<T>['options'])
          }
          onFilterClear={
            onFilterClear
              ? onFilterClear
              : showCommonFilter
              ? () => defaultOnFilterClear()
              : undefined
          }
          isFilterClearDisabled={
            showCommonFilter
              ? defaultFilter.includes(MenuTypeStatus.all)
              : isFilterClearDisabled
          }
          showDescriptionPanel={showDescriptionPanel}
          buttonsContainerCsx={buttonsContainerCsx}
        />
      </Box>
      {showDescriptionPanel && (
        <Box className="descriptionPanel">
          {itemPressed.id !== emptyButton.id ? (
            children &&
            children({
              onSelect: handleItemSelection,
              isButtonSelected: some(selectedItems, ['id', itemPressed.id]),
            })
          ) : (
            <Box className="emptySectionContainer">
              <NoElementsFound
                text={emptySectionMessage}
                vertical
                showBorder={false}
              />
            </Box>
          )}
        </Box>
      )}
      {((allowAssociateMultiple && showDescriptionPanel) ||
        showSelectedButtons) && (
        <Box className="selectedOptions">
          <Box className="selectedOptionsHeaderContainer">
            <Typography variant="body" fontWeight="medium" align="center">
              {selectedSectionTitle ??
                t('app.modals.optionsModal.selectedOptions')}
            </Typography>
          </Box>

          {selectedItems.length > 0 ? (
            <Box className="selectedOptionsContent">
              {selectedItems.map((item, index) => (
                <Box
                  key={`selected-card-${item.id}-${item.menuTypeId?.[0]}`}
                  csx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}>
                  <Card.Item
                    title={item.title}
                    subTitle={
                      item.entity
                        ? `(${t(
                            `menuScreen.common.bucket.${item.entity}.singular`,
                          )})${item.description ? '\n' : ''}`
                        : item.roleIds === undefined
                        ? item.description
                        : undefined
                    }
                    onClick={() => handleOnClick(item, true)}
                    onRemoveClick={() => handleOnDelete(item)}
                    showRemoveIcon={true}
                    isSelected={
                      (showDescriptionPanel ? itemPressed.id : 0) === item.id
                        ? true
                        : undefined
                    }
                  />
                  {showSubcategoryDropdown && (
                    <Box csx={{ paddingRight: 8 }}>
                      <SubcategoryDropdownSection
                        categoryId={Number(item.id)}
                        subcategoryId={item.subcategoryId ?? 0}
                        onChange={newSubcategoryId => {
                          setSelectedItems(
                            selectedItems.map((selectedItem, _index) =>
                              index !== _index
                                ? selectedItem
                                : {
                                    ...selectedItem,
                                    subcategoryId: newSubcategoryId,
                                  },
                            ) as IItemSelection[],
                          );
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          ) : (
            <Box className="noOptionsSelectedContainer">
              <NoElementsFound
                text={noCardSelectedMessage}
                vertical
                showBorder={false}
              />
            </Box>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default GenericSelectionModal;
