import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRefreshSettingsWeb from '@app/hooks/useRefreshSettingsWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  actionCreatorsCustomersWeb,
  actionCreatorsMenuWeb,
  actionCreatorsReports,
  actionCreatorsSettingsWeb,
  actionCreatorsTableLayoutWeb,
} from '@app/state';
import { checkIfTableNameHasError } from '@app/state/settings/tableLayoutWebActions';
import { store, useAppDispatch } from '@app/state/store';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  CoreRootState,
  TDifferences,
  actionCreatorsTableLayout,
  ignoredFieldsToSave,
} from '@westondev/tableturn-core';
import { diff } from 'deep-diff';
import { AnimatePresence, m, useAnimate, usePresence } from 'framer-motion';
import { useEffect } from 'react';
import { SAVE_BAR_HEIGHT, saveAndCancelBarStyles } from './styles';
import {
  ISaveAndCancelBar,
  SAVE_AND_CANCEL_BAR_ID,
  SaveAndCancelBarTypes,
} from './types';

const SaveAndCancelBar = ({ t, type }: ISaveAndCancelBar) => {
  // Redux
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithOrg();

  const { cancelChangesOnCancelPressWeb, saveMenuChangesOnSaveClickWeb } =
    bindActionCreators(actionCreatorsMenuWeb, dispatch);

  const {
    cancelCustomerChangesOnCancelPressWeb,
    saveCustomerChangesOnSaveClickWeb,
  } = bindActionCreators(actionCreatorsCustomersWeb, dispatch);

  const {
    saveSettingsChangesOnSaveClickWeb,
    cancelSettingsChangesOnCancelPressWeb,
  } = bindActionCreators(actionCreatorsSettingsWeb, dispatch);

  const saveAllChangesWeb = bindActionCreators(
    actionCreatorsTableLayoutWeb.saveAllChangesWeb,
    dispatch,
  );
  const cancelTableLayoutChanges = bindActionCreators(
    actionCreatorsTableLayout.cancelTableLayoutChanges,
    dispatch,
  );

  const { cancelReportsChangesOnPressWeb, saveReportsChangesOnSaveClickWeb } =
    bindActionCreators(actionCreatorsReports, dispatch);

  // Local state
  const hasChanges = useRootSelector(state => {
    switch (type) {
      case SaveAndCancelBarTypes.MENU:
        return (
          state.menu.changeData.hasChanges ||
          state.menu.changeFreshSheetData.hasChanges ||
          state.menu.changeMassUpdateData.hasChanges
        );
      case SaveAndCancelBarTypes.SETTINGS:
        return state.settings.changeData.hasChanges;
      case SaveAndCancelBarTypes.CUSTOMERS:
        return state.customers.changeData.hasChanges;
      case SaveAndCancelBarTypes.REPORTS:
        return state.reports.changeData.hasChanges;
      case SaveAndCancelBarTypes.TABLE_LAYOUT: {
        const tableLayout = state.tableLayout;
        const differences = diff(
          tableLayout.savedData,
          tableLayout.currentEditData,
        );
        if (differences) {
          const filteredDifferences = (differences as TDifferences).filter(
            difference => {
              return !ignoredFieldsToSave.includes(difference.path[3]);
            },
          );
          return !(
            filteredDifferences.length === 0 &&
            tableLayout.currentEditLayout.undoList.length === 0
          );
        }
        return false;
      }
    }
  });

  const onRefreshSettings = useRefreshSettingsWeb();
  const onRefresh = useRefreshMenuWeb();

  const handleSaveClick = async () => {
    switch (type) {
      case SaveAndCancelBarTypes.MENU:
        return saveMenuChangesOnSaveClickWeb(onRefresh);
      case SaveAndCancelBarTypes.SETTINGS:
        return saveSettingsChangesOnSaveClickWeb(
          () => onRefreshSettings(store.getState() as unknown as CoreRootState),
          () => null,
          navigate,
        );
      case SaveAndCancelBarTypes.CUSTOMERS:
        return saveCustomerChangesOnSaveClickWeb(
          () => null, // TODO: add onRefresh
          () => null,
          navigate,
        );
      case SaveAndCancelBarTypes.TABLE_LAYOUT: {
        const hasError = await dispatch(checkIfTableNameHasError());
        if (!hasError) {
          return saveAllChangesWeb();
        }
        return;
      }
      case SaveAndCancelBarTypes.REPORTS:
        dispatch(saveReportsChangesOnSaveClickWeb());
        return;
    }
  };

  const handleCancelClick = () => {
    switch (type) {
      case SaveAndCancelBarTypes.MENU:
        return cancelChangesOnCancelPressWeb(navigate);
      case SaveAndCancelBarTypes.SETTINGS:
        return cancelSettingsChangesOnCancelPressWeb();
      case SaveAndCancelBarTypes.CUSTOMERS:
        return cancelCustomerChangesOnCancelPressWeb(navigate);
      case SaveAndCancelBarTypes.TABLE_LAYOUT:
        return cancelTableLayoutChanges();
      case SaveAndCancelBarTypes.REPORTS:
        return cancelReportsChangesOnPressWeb();
    }
  };

  return (
    <AnimatePresence>
      {hasChanges ? (
        <SaveAndCancelContainer>
          <Box csx={saveAndCancelBarStyles}>
            <Typography color="darkGrey">
              {t('validations.unsavedChanges')}...
            </Typography>
            <Button csx={{ width: 'auto' }} onClick={handleCancelClick}>
              {t('commonButtons.discard')}
            </Button>
            <Button
              csx={{ minWidth: '100px' }}
              variant="primary"
              icon={<Icon name="MdSave" />}
              onClick={handleSaveClick}>
              {t('commonButtons.save')}
            </Button>
          </Box>
        </SaveAndCancelContainer>
      ) : null}
    </AnimatePresence>
  );
};

const SaveAndCancelContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(
          scope.current,
          {
            height: SAVE_BAR_HEIGHT,
          },
          {
            duration: 0.2,
          },
        );
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(
          scope.current,
          {
            height: 0,
          },
          {
            duration: 0.2,
          },
        );
        safeToRemove();
      };
      exitAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresent]);

  return (
    <m.div ref={scope} id={SAVE_AND_CANCEL_BAR_ID} initial={{ height: 0 }}>
      {children}
    </m.div>
  );
};

export default SaveAndCancelBar;
