import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  selectCategories,
  selectCombos,
  selectMenuTypes,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IComboTableRow,
  IPair,
  iPairSortingValues,
  menuCombosTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CategoryColumn from '../../../Items/ItemsList/ItemsTable/CategoryColumn';
import ItemName from '../../../Items/ItemsList/ItemsTable/ItemName';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import TaxColumn from '../../../Items/ItemsList/ItemsTable/TaxColumn';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import { columnHelper, ROW_ID_PREFIX } from './types';

const CombosTable = ({ filterParams, t }: IMainScreenTable) => {
  const combos = useSelector(selectCombos);
  const categories = useSelector(selectCategories);
  const subcategories = useSelector(selectSubcategories);
  const menuTypes = useSelector(selectMenuTypes);
  const taxes = useSelector(currentTaxesSettingsSelector);

  const onSort = useSort<IComboTableRow>('title');

  const combosArray = menuCombosTableFactoryCore(
    combos,
    categories,
    subcategories,
    menuTypes,
    taxes,
  );

  const combosArrayFiltered = useMemo(
    () =>
      filter<IComboTableRow>(
        combosArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, combosArray],
  );

  const customFilterFn = (row: Row<IComboTableRow>) => {
    return (
      row.depth > 0 ||
      combosArrayFiltered.findIndex(item => item.id === row.original.id) !== -1
    );
  };

  const iPairSortingFn = (
    rowA: Row<IComboTableRow>,
    rowB: Row<IComboTableRow>,
    columnId: string,
  ) => {
    return iPairSortingValues(
      rowA.original?.[columnId as keyof IComboTableRow] as IPair[],
      rowB.original?.[columnId as keyof IComboTableRow] as IPair[],
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<IComboTableRow>,
    rowB: Row<IComboTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof IComboTableRow] as string,
      rowB.original[columnId as keyof IComboTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          const isSubRow = info.row.depth !== 0;
          const comboId = isSubRow
            ? info.row.getParentRow()?.original.id
            : info.row.original.id;
          const comboTitle =
            (isSubRow
              ? info.row.getParentRow()?.original.title
              : info.row.original.title) || '';
          return (
            <ItemName
              pathWithId={`/menu/combos/combos/${comboId}`}
              depth={info.row.depth}
              breadcrumbTitle={comboTitle}
              status={info.row.original.status}
              isHidden={info.row.original.isHidden}
              text={info.getValue()}
              statusPath={`selectedMode.currentMenu.combos.${comboId}.active`}
              menuTypeId={info.row.original.menuTypeId[0]}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('price', {
        header: t('menuScreen.itemsMain.table.headers.price'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 150,
      }),
      columnHelper.accessor('menuTypeId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('categoryId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('subcategoryId', {
        filterFn: 'customFilterFn' as keyof FilterFns,
      }),
      columnHelper.accessor('categoriesSubcategories', {
        header: t('menuScreen.itemsMain.table.headers.categoriesSubcategories'),
        cell: info => {
          return <CategoryColumn value={info.getValue()} />;
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        size: 250,
      }),
      columnHelper.accessor('itemGroups', {
        header: t('menuScreen.common.bucket.itemGroups.plural'),
        cell: info => {
          return (
            <OverflowText
              text={
                info.getValue() === undefined
                  ? t('menuScreen.combosMain.table.noItemGroups')
                  : (info.getValue() as string)
              }
            />
          );
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        size: 500,
      }),
      columnHelper.accessor('taxes', {
        header: t('menuScreen.itemsMain.table.headers.taxes'),
        cell: info => {
          return <TaxColumn truncateLength={20} value={info.getValue()} />;
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        minSize: 200,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        canExpandAll
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={combosArray}
        manualSorting={false}
        columnVisibility={{
          menuTypeId: false,
          categoryId: false,
          subcategoryId: false,
        }}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default CombosTable;
