import Box from '@app/components/common/Box';
import Icon from '@app/components/common/Icon';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  selectCategories,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IPair,
  iPairSortingValues,
  ISubcategoryTableRow,
  menuSubcategoriesTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TaxColumn from '../../../Items/ItemsList/ItemsTable/TaxColumn';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const SubcategoriesTable = ({ filterParams, t }: IMainScreenTable) => {
  const categories = useSelector(selectCategories);
  const subcategories = useSelector(selectSubcategories);
  const taxes = useSelector(currentTaxesSettingsSelector);

  const onSort = useSort<ISubcategoryTableRow>('title');

  const subcategoriesArray = menuSubcategoriesTableFactoryCore(
    subcategories,
    categories,
    taxes,
  );

  const subcategoriesArrayFiltered = useMemo(
    () =>
      filter<ISubcategoryTableRow>(
        subcategoriesArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, subcategoriesArray],
  );

  const customFilterFn = (row: Row<ISubcategoryTableRow>) => {
    return (
      row.depth > 0 ||
      subcategoriesArrayFiltered.findIndex(
        subcategory => subcategory.id === row.original.id,
      ) !== -1
    );
  };

  const iPairSortingFn = (
    rowA: Row<ISubcategoryTableRow>,
    rowB: Row<ISubcategoryTableRow>,
    columnId: string,
  ) => {
    return iPairSortingValues(
      rowA.original?.[columnId as keyof ISubcategoryTableRow] as IPair[],
      rowB.original?.[columnId as keyof ISubcategoryTableRow] as IPair[],
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<ISubcategoryTableRow>,
    rowB: Row<ISubcategoryTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof ISubcategoryTableRow] as string,
      rowB.original[columnId as keyof ISubcategoryTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          return (
            <NameLink
              name={info.getValue()}
              path={`/menu/subcategories/${info.row.original.id}`}
              status={info.row.original.status}
              statusPath={`selectedMode.currentMenu.subcategories.${info.row.original.id}.active`}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.categoryMain.table.headers.itemCount'),
        cell: info => {
          return (
            <Box csx={{ display: 'flex', width: '100%' }}>
              <Box csx={{ flex: 1 }}>{info.row.original.itemCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.comboCount}</Box>
            </Box>
          );
        },
        size: 150,
      }),
      columnHelper.accessor('category', {
        header: t('menuScreen.common.bucket.categories.singular'),
        cell: info => {
          return (
            <Typography>
              {info.getValue() ||
                t(
                  'menuScreen.categoryDetails.associations.subcategories.noCategory',
                )}
            </Typography>
          );
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        size: 200,
      }),
      columnHelper.accessor('customizer', {
        header: t('app.modals.itemsDiff.registerInfo.customizer'),
        cell: info => {
          return (
            <Box
              csx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {info.getValue() && (
                <Icon name="MdCheck" color="black" size="30px" />
              )}
            </Box>
          );
        },
        size: 110,
      }),
      columnHelper.accessor('taxes', {
        header: t('menuScreen.itemsMain.table.headers.taxes'),
        cell: info => {
          return <TaxColumn truncateLength={20} value={info.getValue()} />;
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={subcategoriesArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default SubcategoriesTable;
