import Box from '@app/components/common/Box';
import Divider from '@app/components/common/Divider';
import Typography from '@app/components/common/Typography';
import {
  IReportsStore,
  useLoadReportsChangeData,
} from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router-dom';
import { getPathWithOrgData } from '@app/helpers/navigation';
import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { useCallback } from 'react';
import { REPORTS_SCREEN_BY_BUCKET_NAME } from '@app/constants';
import Accordion from '@app/components/common/Accordion';
import ReportSettingsSection from './ReportSettingsSection';
import ReportSchedulingSection from './ReportSchedulingSection';

interface IReportForm extends WithTranslation {
  bucket: keyof IReportsStore;
}

const ReportForm = ({ t, bucket }: IReportForm) => {
  const navigate = useNavigateWithOrg();

  const id = useParams().id;
  const isAdd = useMatch(getPathWithOrgData('/reports/:bucket/add'));

  const report = useLoadReportsChangeData<typeof bucket>(
    isAdd ? 0 : Number(id),
    bucket,
  );

  const handleGoBack = useCallback(() => {
    const path = REPORTS_SCREEN_BY_BUCKET_NAME[bucket];
    if (!id) {
      return navigate(path);
    }
    navigate(`${path}/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, id]);

  if (!report) return;

  return (
    <Box
      csx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Button onClick={handleGoBack} variant="transparent">
          <Icon name="MdArrowBack" size="32px" />
        </Button>

        <Typography variant="heading" fontWeight="medium">
          {t(`reports.addEditScreen.${bucket}${id ? 'Edit' : 'Add'}`)}
        </Typography>
      </Box>
      <Divider
        lineCsx={theme => ({
          borderColor: theme.colors.lightGrey,
        })}
      />

      <Accordion>
        <ReportSettingsSection isExpanded bucket={bucket} />
        <ReportSchedulingSection />
      </Accordion>
    </Box>
  );
};

export default ReportForm;
