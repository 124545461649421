import { useTheme } from '@emotion/react';
import {
  TChange,
  getDiffReportFactoryLabeledFilterValues,
} from '@westondev/tableturn-core';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import { useMemo } from 'react';
import { store } from '@app/state/store';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import useRootSelector from '@app/hooks/useRootSelector';
import Grid from '@app/components/common/Grid';

const BaseReportDiffModal = () => {
  const theme = useTheme();

  // Redux
  const differences = useRootSelector(
    state => state.reports.changeData.differences,
  );
  const [
    nonArrayFilterChanges,
    arrayFilterChanges,
    nonArraySchedulingChanges,
    arraySchedulingChanges,
  ] = useMemo(() => {
    const diffObject = getDiffReportFactoryLabeledFilterValues(
      differences,
      store.getState() as any,
    );

    const filterChanges = diffObject?.filters?.reduce(
      (acc, change) => {
        if (Array.isArray(change.value)) {
          acc[1].push(change);
        } else {
          acc[0].push(change);
        }
        return acc;
      },
      [[], []] as [TChange[], TChange[]],
    ) ?? [[], []];

    const schedulingChanges = diffObject?.scheduling?.reduce(
      (acc, change) => {
        if (Array.isArray(change.value)) {
          acc[1].push(change);
        } else {
          acc[0].push(change);
        }
        return acc;
      },
      [[], []] as [TChange[], TChange[]],
    ) ?? [[], []];

    return [...filterChanges, ...schedulingChanges];
  }, [differences]);

  const formatValue = (value: unknown, field: string, textValue?: string) => {
    if (textValue) {
      return textValue;
    }
    return _formatValue(value, field === 'isActive' ? 'active' : field);
  };

  const renderChange = (change: TChange) => {
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        colorFields={['backgroundColor', 'textColor']}
        defaultColor={{
          backgroundColor: theme.colors.cardWhite,
          textColor: theme.colors.black,
        }}
      />
    );
  };

  return (
    <>
      {nonArrayFilterChanges.length > 0 && (
        <Grid columnGap={20} rowGap={10}>
          {nonArrayFilterChanges.map(change => (
            <Grid.Item
              mb={12}
              sm={6}
              md={4}
              lg={4}
              key={`basic_change_${change.field}`}>
              {renderChange(change)}
            </Grid.Item>
          ))}
        </Grid>
      )}
      {arrayFilterChanges.length > 0 && (
        <Grid columnGap={20} rowGap={10}>
          {arrayFilterChanges.map(change => (
            <Grid.Item key={`basic_change_${change.field}`}>
              {renderChange(change)}
            </Grid.Item>
          ))}
        </Grid>
      )}

      {nonArraySchedulingChanges.length > 0 && (
        <Grid columnGap={20} rowGap={10}>
          {nonArraySchedulingChanges.map(change => (
            <Grid.Item
              mb={12}
              sm={6}
              md={4}
              lg={4}
              key={`basic_change_${change.field}`}>
              {renderChange(change)}
            </Grid.Item>
          ))}
        </Grid>
      )}
      {arraySchedulingChanges.length > 0 && (
        <Grid columnGap={20} rowGap={10}>
          {arraySchedulingChanges.map(change => (
            <Grid.Item key={`basic_change_${change.field}`}>
              {renderChange(change)}
            </Grid.Item>
          ))}
        </Grid>
      )}
    </>
  );
};

export default BaseReportDiffModal;
