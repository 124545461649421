import Box from '@app/components/common/Box';
import { customersMainScreenTableFactory } from '@westondev/tableturn-core';
import { useSelector } from 'react-redux';

import Table from '@app/components/common/Table';
import SectionLayer from '@app/components/LoggedIn/Menu/common/SectionLayer';
import { selectCustomers } from '@app/state/customers/customersSelectors';

import ActionButtons from '@app/components/common/ActionButtons';
import Card from '@app/components/common/Card';
import Divider from '@app/components/common/Divider';
import Grid from '@app/components/common/Grid';
import SearchInput from '@app/components/common/SearchInput';
import Typography from '@app/components/common/Typography';
import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import { actionCreatorsCustomersWeb } from '@app/state';
import { selectOrganizationId } from '@app/state/selectors/appSelectors';
import { store, useAppDispatch } from '@app/state/store';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OverflowText from '../../Menu/Items/ItemsList/ItemsTable/OverflowText';
import NameLink from '../../Menu/ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const CustomersList = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const customers = useSelector(selectCustomers);
  const organizationId = useSelector(selectOrganizationId);
  const navigate = useNavigateWithOrg();

  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const customersArray = useMemo(
    () => customersMainScreenTableFactory(customers),
    [customers],
  );

  const fetch = async (append: boolean, textValue?: string) => {
    const actualOrganizationId = selectOrganizationId(store.getState());
    if (!actualOrganizationId) {
      return;
    }

    setIsFetching(true);
    const {
      success,
      totalPages: _totalPages,
      totalCustomers,
    } = await dispatch(
      actionCreatorsCustomersWeb.fetchCustomersWeb(
        page + 1,
        append,
        textValue || inputValue,
      ),
    );
    setIsFetching(false);
    if (!success) return;
    setHasFetched(true);
    setCount(totalCustomers);
    setTotalPages(_totalPages);
    setPage(_page => _page + 1);
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        cell: info => {
          return (
            <NameLink
              name={info.getValue()}
              path={`/customers/${info.row.original.id}`}
              hideStatus
              status
              statusPath=""
              addToBreadcrumb={false}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('email', {
        header: t('customersScreen.basic.email'),
        cell: info => <OverflowText rows={1} text={info.getValue()} />,
        minSize: 200,
      }),
      columnHelper.accessor('phoneNumber', {
        header: t('customersScreen.basic.phoneNumber'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
      columnHelper.accessor('avgSpend', {
        header: t('customersScreen.orders.summary.avgSpend'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
      columnHelper.accessor('lifetimeSpend', {
        header: t('customersScreen.orders.summary.lifetimeSpend'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        minSize: 150,
      }),
    ],
    [t],
  );

  const handleAddNewCustomer = () => navigate('/customers/add');

  useEffect(() => {
    fetch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const loadMore = () => {
    if (totalPages <= page + 1 || !hasFetched || isFetching) return;
    fetch(true);
  };

  return (
    <Card
      csx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        csx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          height: '100%',
        }}>
        <Grid columnGap={15} rowGap={15}>
          <Grid.Item
            mb={8}
            sm={7}
            md={4}
            lg={4}
            mbOffset={hasFetched ? 0 : 2}
            smOffset={hasFetched ? 0 : 3}
            mdOffset={hasFetched ? 0 : 6}
            lgOffset={hasFetched ? 0 : 7}>
            <Box
              csx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                height: '100%',
              }}>
              <SearchInput
                onChange={input => {
                  setInputValue(input);
                  setPage(-1);
                  setTotalPages(0);
                }}
                debounceTimeout={300}
                onDebounce={text => {
                  setInputValue(text);
                  fetch(false, text);
                }}
                value={inputValue}
              />
            </Box>
          </Grid.Item>
          {hasFetched && (
            <Grid.Item
              mb={2}
              sm={2}
              md={4}
              lg={3}
              mbOffset={2}
              smOffset={3}
              mdOffset={2}
              lgOffset={4}>
              <Box
                csx={{
                  display: 'flex',
                  gap: 10,
                  height: '100%',
                }}>
                <Divider direction="vertical" />
                <Box csx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>{`${count} Total Customers`}</Typography>
                </Box>
              </Box>
            </Grid.Item>
          )}
          <Grid.Item mb={2} sm={2} md={2} lg={1}>
            <Box
              csx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <ActionButtons
                optionList={[
                  {
                    text: t(
                      'menuScreen.menuLocation.actionButtons.addNewCategory',
                    ),
                    handler: handleAddNewCustomer,
                  },
                ]}
              />
            </Box>
          </Grid.Item>
        </Grid>
        <Divider />
        <Box csx={{ flexGrow: 1, overflowY: 'auto' }}>
          {hasFetched && (
            <Box
              csx={{
                height: '100%',
              }}>
              <Table
                scrollEnabled
                nestedScrollEnabled
                data={customersArray}
                columns={COLUMN_VALUES}
                cellCsx={{
                  height: '70px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
                rowIdPrefix={ROW_ID_PREFIX}
                onEndReached={loadMore}
                noDataPlaceholder={
                  <SectionLayer
                    title={t('customersScreen.mainScreen.emptyDataText')}
                    csx={{
                      justifyContent: 'center',
                      paddingTop: 30,
                      paddingBottom: 20,
                    }}
                  />
                }
              />
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default CustomersList;
