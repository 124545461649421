import Card from '@app/components/common/Card';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { MenuTypeStatus } from '@app/components/common/SelectionModal/MenuTypesSelectionModal/Filter/types';
import UsersSelectionModal from '@app/components/common/SelectionModal/UsersSelectionModal';
import AssignedElements from '@app/components/LoggedIn/Menu/AssignedElements';
import useRootSelector from '@app/hooks/useRootSelector';
import {
  actionCreatorsReports,
  settingsSelectors,
  usersCardFactory,
  usersSelectors,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

const EMPTY_ARRAY: number[] = [];

const ReportSchedulingRecipientsSubsection = ({ t }: WithTranslation) => {
  const dispatch = useDispatch();
  const setValues = bindActionCreators(
    actionCreatorsReports.handleReportsChange,
    dispatch,
  );

  const recipients = useRootSelector(
    state => state.reports.changeData.data?.recipients ?? EMPTY_ARRAY,
  );
  const isSchedulingEnabled = useRootSelector(
    state => state.reports.changeData.data?.isSchedulingEnabled,
  );

  const users = useSelector(usersSelectors.selectUsers);
  const roles = useSelector(settingsSelectors.selectRoles);

  const formattedUsersArray = useMemo(() => {
    return usersCardFactory(users).map(userCard => ({
      ...userCard,
      roles: userCard.roleIds.map(roleId => roles[roleId]?.name),
      description: userCard.email,
      isActive: undefined,
    }));
  }, [users, roles]);

  const selectedUserCards = useMemo(() => {
    const recipientSet = new Set(recipients);
    return formattedUsersArray.filter(userCard =>
      recipientSet.has(userCard.id),
    );
  }, [formattedUsersArray, recipients]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const handleOnAssociate = (selectedUsers: IItemSelection[]) => {
    const selectedUserIds = selectedUsers?.map(user => Number(user.id)) ?? [];
    setValues(_values => ({
      ..._values,
      recipients: selectedUserIds,
    }));
    closeModal();
  };

  return (
    <>
      <UsersSelectionModal
        buttons={formattedUsersArray}
        onAssociate={handleOnAssociate}
        onModalClose={closeModal}
        active={isModalOpen}
        allowAssociateMultiple
        showDescriptionPanel={false}
        associateOnSelect={false}
        showSelectedButtons
        showCommonFilter={false}
        defaultFilterValue={MenuTypeStatus.all}
        buttonsContainerCsx={{
          gridTemplateColumns:
            'repeat(auto-fill, minmax(240px, 1fr)) !important',
        }}
      />
      <AssignedElements
        type="users"
        elements={selectedUserCards}
        noElementsMessage={t('reports.fields.noRecipients')}
        renderItem={item => {
          const user = item as (typeof selectedUserCards)[0];
          return (
            <Card.Item
              title={user.title}
              subTitle={user.email}
              showStatus={false}
              isActive={user.isActive}
              showRemoveIcon={isEditMode}
              isAnimated={false}
              onRemoveClick={() => {
                setValues(_values => ({
                  ..._values,
                  recipients: recipients.filter(id => id !== user.id),
                }));
              }}
              csx={theme => ({
                border: `2px solid ${theme.colors.lightGrey} !important`,
              })}
            />
          );
        }}
        subCardProps={{
          title: t('reports.fields.recipients'),
          required: !!isSchedulingEnabled,
          actionOptions: [
            {
              text: 'text',
              icon: false,
              handler: () => setIsModalOpen(true),
            },
          ],
          isActionButtonDisabled: !isSchedulingEnabled,
          onEditMode: () => setIsEditMode(true),
          isEditButtonDisabled: !isSchedulingEnabled,
          isEditButtonActive: isEditMode,
        }}
        cardsContainerContentCsx={{
          gridTemplateColumns:
            'repeat(auto-fill, minmax(240px, 1fr)) !important',
        }}
      />
    </>
  );
};

export default ReportSchedulingRecipientsSubsection;
