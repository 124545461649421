import ActionButtons from '@app/components/common/ActionButtons';
import Card from '@app/components/common/Card';
import Dropdown from '@app/components/common/Dropdown';
import { ELabelVariants } from '@app/components/common/Dropdown/SingleSelectDropdown/types';
import SelectionModal from '@app/components/common/SelectionModal';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import StatusCardsContainer from '@app/components/common/StatusCardsContainer';
import { EStatusCardsContainer } from '@app/components/common/StatusCardsContainer/types';
import { menuTypeVersionsFactory } from '@app/helpers/factories/menu/cardFactories/menuTypes/menuTypeVersionsFactory';

import useNavigateWithOrg from '@app/hooks/useNavigateWithOrg';
import useRefreshMenuWeb from '@app/hooks/useRefreshMenuWeb';
import useRootSelector from '@app/hooks/useRootSelector';
import { actionCreatorsApp, actionCreatorsMenuWeb } from '@app/state';
import {
  makeSelectMenuTypeVersions,
  selectBucketSelected,
  selectMenuMode,
} from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  BreadCrumbAction,
  FormMode,
  MenuTypes,
  MenuTypesSectionRef,
  SectionId,
  THomeElementsWithBucketType,
  actionCreatorsMenu,
  bucketTypeMap,
} from '@westondev/tableturn-core';
import { ParseKeys } from 'i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const valueStatus: Record<number, 'all' | EStatusCardsContainer> = {
  0: 'all',
  1: EStatusCardsContainer.ACTIVE,
  2: EStatusCardsContainer.INACTIVE,
};

interface IMenuTypesSection {
  selectedMenuTypeVersionId: number;
  setSelectedMenuTypeVersionId: (id: number) => void;
  title: string;
  description?: string;
  sectionDescription: string;
  onNewMenuType: (
    menuTypeIds: IItemSelection[],
    firstActiveMenuType?: IItemSelection,
  ) => void;
  emptyMenuTypesPath: 'itemDetails' | 'comboDetails' | 'specialDetails';
}

const SECTION_ID = SectionId.MENU_TYPES;
const getMemoizedItemData = makeSelectMenuTypeVersions(SECTION_ID);

const MenuTypesSection = forwardRef<MenuTypesSectionRef, IMenuTypesSection>(
  (
    {
      selectedMenuTypeVersionId,
      setSelectedMenuTypeVersionId,
      emptyMenuTypesPath,
      title,
      description,
      sectionDescription,
      onNewMenuType,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      openExistingTypes() {
        setAdditionModal(true);
        setIsOpenByCreation(true);
      },
      closeExistingTypes() {
        setAdditionModal(false);
      },
      setFirstActiveMenuType() {
        setFirstActiveId();
      },
    }));

    // Redux
    const dispatch = useDispatch();
    const { menuTypeVersions } = useSelector(getMemoizedItemData);
    const currentMenu = useRootSelector(
      state => state.menu.selectedMode.currentMenu,
      shallowEqual,
    );

    const bucketSelected = useSelector(selectBucketSelected);

    const menuTypeMode = useRootSelector(
      state => state.menu.selectedMode.menuType,
    );

    const itemId = useRootSelector(
      state => state.menu.changeData.id,
      shallowEqual,
    );
    const mode = useSelector(selectMenuMode);
    const loadingModal = useRootSelector(state => state.app.isLoadingModal);

    const { removeMenuTypeFromItem, resetChangeData } = bindActionCreators(
      actionCreatorsMenu,
      dispatch,
    );

    const {
      checkForChangesAndNavigateWeb,
      addBreadcrumbLocationWeb,
      breadCrumbPopNavigationWeb,
    } = bindActionCreators(actionCreatorsMenuWeb, dispatch);

    const setShowToast = bindActionCreators(
      actionCreatorsApp.setShowToast,
      dispatch,
    );

    // Local state
    const { t } = useTranslation();
    const onRefresh = useRefreshMenuWeb();
    const [isEditMode, setIsEditMode] = useState(false);
    const [additionModal, setAdditionModal] = useState(false);
    const [isOpenByCreation, setIsOpenByCreation] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(1);

    const navigate = useNavigateWithOrg();

    const formattedMenuTypes = menuTypeVersions
      ? menuTypeVersionsFactory(menuTypeVersions)
      : [];

    const menuTypes = formattedMenuTypes.map(_menuType => {
      const currentMenuType = currentMenu.menuTypes[_menuType.id];
      const bucketHasTopTag =
        bucketSelected === null
          ? false
          : ['items', 'combos'].includes(bucketSelected);

      const hasTopTag =
        bucketHasTopTag &&
        !!currentMenuType.homeScreenElements.find(
          homeElement =>
            homeElement.type in bucketTypeMap &&
            bucketTypeMap[homeElement.type as THomeElementsWithBucketType] ===
              bucketSelected &&
            homeElement.bucketId === itemId,
        );

      return {
        id: _menuType.id,
        title: _menuType.name,
        status: menuTypeVersions[_menuType.id].active,
        groupStatus: currentMenu.menuTypes[_menuType.id].active
          ? EStatusCardsContainer.ACTIVE
          : EStatusCardsContainer.INACTIVE,
        onClick: () => setSelectedMenuTypeVersionId(_menuType.id),
        onRemoveClick: () => handleRemoveMenuTypeVersion(_menuType.id),
        topTag: hasTopTag
          ? t('menuScreen.itemDetails.menuTypes.onHomeScreen')
          : undefined,
      };
    });

    // useEffect(() => {
    //   if (!selectedMenuTypeVersionId) return;

    //   const selectedMenuTypeVersion = menuTypes.find(
    //     menuType => menuType.id === selectedMenuTypeVersionId,
    //   );
    //   const status = selectedMenuTypeVersion?.groupStatus === 'active' ? 1 : 2;

    //   if (status !== activeDropdown) {
    //     setActiveDropdown(0);
    //   }

    // }, [menuTypes, selectedMenuTypeVersionId, activeDropdown]);

    const setFirstActiveId = () => {
      const firstActiveMenuType = menuTypes.find(
        menuType => menuType.groupStatus === EStatusCardsContainer.ACTIVE,
      );

      if (firstActiveMenuType) {
        return setSelectedMenuTypeVersionId(firstActiveMenuType.id);
      }
      setActiveDropdown(0);
      if (menuTypes[0] && menuTypes[0].id)
        setSelectedMenuTypeVersionId(menuTypes[0].id);
    };

    const handleRemoveMenuTypeVersion = (menuTypeVersionIdToRemove: number) => {
      const firstActiveMenuType = menuTypes.find(
        menuType =>
          menuType.groupStatus && menuType.id !== menuTypeVersionIdToRemove,
      );
      removeMenuTypeFromItem(
        menuTypeVersionIdToRemove,
        (newMenuTypesIds: string[]) => {
          if (!newMenuTypesIds.includes(`${selectedMenuTypeVersionId}`)) {
            setSelectedMenuTypeVersionId(
              Number(
                firstActiveMenuType
                  ? firstActiveMenuType.id
                  : newMenuTypesIds[0],
              ),
            );
          }
        },
      );
    };

    const getNoElementsMessage = () => {
      switch (activeDropdown) {
        case 0: //All
          return t(
            `menuScreen.${emptyMenuTypesPath}.menuTypes.emptyCardsMessage` as ParseKeys,
          );
        case 1: //Active
          return t(
            `menuScreen.${emptyMenuTypesPath}.menuTypes.emptyActiveCardsMessage` as ParseKeys,
          );
        case 2: //Inactive
          return t(
            `menuScreen.${emptyMenuTypesPath}.menuTypes.emptyInactiveCardsMessage` as ParseKeys,
          );
      }
    };

    const handleAddExistingMenuType = (menuTypeIds: IItemSelection[]) => {
      const firstActiveMenuType = menuTypeIds.find(
        menuType => menuType.isActive,
      );
      if (!firstActiveMenuType) return;

      const currentMenuTypeVersion =
        currentMenu.menuTypes[Number(firstActiveMenuType.id)];
      const currentStatus = currentMenuTypeVersion.active ? 1 : 2;
      if (currentStatus !== activeDropdown) {
        setActiveDropdown(0);
      }

      onNewMenuType(menuTypeIds, firstActiveMenuType);
      setAdditionModal(false);
    };

    return (
      <>
        <SelectionModal
          type="menuTypes"
          detailsScreenProps={{
            currentRelationsIds: Object.keys(menuTypeVersions ?? {}),
            wantedEntity: 'menuTypes',
          }}
          active={additionModal && !loadingModal.active}
          onModalClose={() => {
            setAdditionModal(false);
            if (isOpenByCreation) {
              resetChangeData();
              breadCrumbPopNavigationWeb();
            }
          }}
          onAssociate={handleAddExistingMenuType}
          title={isOpenByCreation ? title : undefined}
          description={description}
          btnCancelText={isOpenByCreation ? t('commonButtons.skip') : undefined}
        />
        <Card.SubCard
          required
          showCardShadow
          title={t('menuScreen.itemDetails.menuTypes.title')}
          description={sectionDescription}
          options={
            <>
              <Dropdown
                data={[
                  [{ label: 'All', value: 0 }],
                  [
                    { label: t('commonTexts.active'), value: 1 },
                    { label: t('commonTexts.inactive'), value: 2 },
                  ],
                ]}
                label="Status"
                labelVariant={ELabelVariants.INSIDE}
                value={activeDropdown}
                onChange={setActiveDropdown}
                csx={{ maxWidth: '120px' }}
              />
              <ActionButtons
                onEditAction={
                  menuTypes.length > 0
                    ? () => setIsEditMode(prev => !prev)
                    : undefined
                }
                isEditButtonDisabled={
                  menuTypeMode !== MenuTypes.MASTER_MENU &&
                  mode !== FormMode.CREATE
                }
                onEditClickDisabled={() =>
                  setShowToast({
                    type: 'info',
                    title: t(
                      'menuScreen.subcategoryMain.disabledActionToast.title',
                    ),
                    description: t('menuScreen.common.editDisabled.menuTypes'),
                  })
                }
                optionList={[
                  {
                    text: t(
                      'menuScreen.menuLocation.actionButtons.addNewMenuType',
                    ),
                    handler: () =>
                      checkForChangesAndNavigateWeb(
                        () =>
                          addBreadcrumbLocationWeb({
                            action: BreadCrumbAction.ADD,
                            text: t(
                              'menuScreen.menuLocation.actionButtons.addNewMenuTypeTag',
                            ),
                            onPress: () => {
                              navigate('/menu/menus/add', {
                                state: {
                                  assignedItemIds: [itemId],
                                  assignedItemMenuTypeId:
                                    selectedMenuTypeVersionId,
                                },
                              });
                            },
                            pathURL: '/menu/menus/add',
                          }),
                        onRefresh,
                        true,
                        false,
                        'menuTypes',
                      ),
                  },
                  {
                    text: t(
                      'menuScreen.menuLocation.actionButtons.addExistingMenuType',
                    ),
                    handler: () => {
                      setAdditionModal(true);
                      setIsOpenByCreation(false);
                    },
                  },
                ]}
                actionDropdownTriggerCsx={{ maxWidth: '50px' }}
              />
            </>
          }>
          <StatusCardsContainer
            data={menuTypes}
            selectedStatus={valueStatus[activeDropdown]}
            activeText={t('menuScreen.itemDetails.menuTypes.active')}
            inactiveText={t('menuScreen.itemDetails.menuTypes.inactive')}
            noElementsMessage={getNoElementsMessage()}
            renderItem={item => {
              const active =
                Number(item.id) === Number(selectedMenuTypeVersionId);
              return (
                <Card.Item
                  {...item}
                  showTag={false}
                  showStatus
                  isSelected={active}
                  isActive={item.status}
                  showRemoveIcon={isEditMode}
                  onRemoveClick={() => item.onRemoveClick()}
                />
              );
            }}
          />
        </Card.SubCard>
      </>
    );
  },
);

MenuTypesSection.displayName = 'MenuTypesSection';

export default MenuTypesSection;
