import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import { WithTranslation } from 'react-i18next';
import HomeSettings from './HomeSettings';
import EndOfDaySettings from './EndOfDaySettings';
import Divider from '@app/components/common/Divider';

const GeneralSettings = ({
  t,
  ...props
}: IAccordionSection & WithTranslation) => {
  return (
    <AccordionSection title={t('loggedIn.commonTitles.general')} {...props}>
      <HomeSettings />
      <Divider
        lineCsx={theme => ({
          borderColor: theme.colors.lightGrey,
          margin: '20px 0',
        })}
      />
      <EndOfDaySettings />
    </AccordionSection>
  );
};

export default GeneralSettings;
