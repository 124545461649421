import { IModal } from '@app/components/common/Modal/types';
import { ICustomerAddress } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

export enum AddressActions {
  CREATE = 'create',
  EDIT = 'edit',
}

export type IAddressInfoModal = WithTranslation &
  Pick<IModal, 'isActive' | 'onModalClose'> & {
    onSave: (newAddress: ICustomerAddress) => void;
    value: ICustomerAddress;
    action: AddressActions;
  };
