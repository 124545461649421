import { customersSelectors } from '@westondev/tableturn-core';

export const {
  createCustomersErrorSelector,
  makeSelectCustomersData,
  selectCustomersDataAndSectionId,
  selectCurrentCustomersSectionId,
  selectCurrentCustomer,
  selectCustomerChangeData,
  selectCustomersErrors,
  selectIsCustomerSet,
  selectCustomers,
  selectCustomerDifferences,
  selectCustomerFinalDiffObject,
  selectCurrentCustomerOriginalData,
} = customersSelectors;
