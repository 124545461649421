import Box from '@app/components/common/Box';
import Tag86 from '@app/components/common/Card/ItemButton/Tag86';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import { selectIngredients, selectItems } from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IIngredientTableRow,
  menuIngredientsTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const IngredientsTable = ({ filterParams, t }: IMainScreenTable) => {
  const ingredients = useSelector(selectIngredients);
  const items = useSelector(selectItems);

  const onSort = useSort<IIngredientTableRow>('title');

  const ingredientsArray = menuIngredientsTableFactoryCore(ingredients, items);

  const ingredientsArrayFiltered = useMemo(
    () =>
      filter<IIngredientTableRow>(
        ingredientsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, ingredientsArray],
  );

  const customFilterFn = (row: Row<IIngredientTableRow>) => {
    return (
      row.depth > 0 ||
      ingredientsArrayFiltered.findIndex(
        ingredient => ingredient.id === row.original.id,
      ) !== -1
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<IIngredientTableRow>,
    rowB: Row<IIngredientTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof IIngredientTableRow] as string,
      rowB.original[columnId as keyof IIngredientTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/modifiers/ingredients/${info.row.original.id}`}
            status={info.row.original.isActive}
            statusPath={`selectedMode.currentMenu.ingredients.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('is86ed', {
        header: t('menuScreen.modifierItemsMain.table.headers.is86ed'),
        cell: info =>
          info.getValue() ? (
            <Tag86 />
          ) : (
            <Typography>
              {t('menuScreen.modifierItemsMain.table.inStock')}
            </Typography>
          ),
        size: 150,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.common.bucket.items.plural'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 100,
      }),
      columnHelper.accessor('items', {
        header: t('menuScreen.ingredientsMain.table.headers.items'),
        cell: info => (
          <OverflowText
            text={info.getValue() || t('register.modals.itemDetails.noItems')}
          />
        ),
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={ingredientsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default IngredientsTable;
