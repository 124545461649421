import Box from '@app/components/common/Box';
import Grid from '@app/components/common/Grid';
import Table from '@app/components/common/Table';
import { formatValue as _formatValue } from '@app/helpers/modals/diffModal';
import {
  selectCurrentCustomer,
  selectCurrentCustomerOriginalData,
  selectCustomerFinalDiffObject,
} from '@app/state/customers/customersSelectors';
import { TChange } from '@app/types';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import {
  getCustomerAddressRowData,
  ICustomer,
  ICustomerDiffObject,
  ISpecialTagDiff,
  phoneNumberMask,
} from '@westondev/tableturn-core';
import { DateTime } from 'luxon';
import { mask } from 'node-masker';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RenderChange from '../../SettingsDiffConfirmationModal/common/RenderChange';
import HeaderDiffModal from '../../common/HeaderDiffModal';

const CustomerDiffModal = ({ t }: WithTranslation) => {
  const originalData = useSelector(
    selectCurrentCustomerOriginalData,
  ) as ICustomer;
  const changeData = useSelector(selectCurrentCustomer) as ICustomer;

  const formattedDifferences = useSelector(
    selectCustomerFinalDiffObject,
  ) as ICustomerDiffObject;

  const formatValue = (value: any, field: string) => {
    if (field === 'phoneNumber') {
      return mask(value, phoneNumberMask);
    }
    return _formatValue(value, field);
  };

  const renderChange = (change: TChange, idx = 0) => {
    if (!change.field) return null;
    return (
      <RenderChange
        change={change}
        formatValue={formatValue}
        key={`${change.field}_${change.id}_${idx}`}
      />
    );
  };

  const renderAddressTable = (addresses: ISpecialTagDiff[]) => {
    const columnHelper = createColumnHelper<ISpecialTagDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'isDefault',
        header: t('customersScreen.addressInfo.table.isDefault'),
        meta: {
          getCellContext: (context: CellContext<ISpecialTagDiff, unknown>) => {
            const rowData = context.row.original;
            return {
              removed: rowData.action === 'remove',
            };
          },
        },
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'isDefault');

          return (
            <RenderChange
              change={{
                field: 'isDefault',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].isDefault
                    : changeData.addresses[rowData.id].isDefault,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'addressType',
        header: t('customersScreen.addressInfo.table.addressType'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'addressType');
          return (
            <RenderChange
              change={{
                field: 'addressType',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].addressType
                    : changeData.addresses[rowData.id].addressType,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'streetLine1',
        header: t('customersScreen.addressInfo.table.streetLine1'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged = info
            .getValue()
            .some(change => change.field === 'streetLine1');
          return (
            <RenderChange
              change={{
                field: 'streetLine1',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].streetLine1
                    : changeData.addresses[rowData.id].streetLine1,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'streetLine2',
        header: t('customersScreen.addressInfo.table.streetLine2'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'streetLine2');
          return (
            <RenderChange
              change={{
                field: 'streetLine2',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].streetLine2 || ''
                    : changeData.addresses[rowData.id].streetLine2 || '',
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'city',
        header: t('customersScreen.addressInfo.table.city'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'city');
          return (
            <RenderChange
              change={{
                field: 'city',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].city
                    : changeData.addresses[rowData.id].city,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'state',
        header: t('customersScreen.addressInfo.table.state'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'state');
          return (
            <RenderChange
              change={{
                field: 'state',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].state
                    : changeData.addresses[rowData.id].state,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'postalCode',
        header: t('customersScreen.addressInfo.table.postalCode'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'postalCode');
          return (
            <RenderChange
              change={{
                field: 'postalCode',
                label: '',
                value:
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id].postalCode
                    : changeData.addresses[rowData.id].postalCode,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'addressName',
        header: t('customersScreen.addressInfo.table.addressName'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change =>
                  ['hotelName', 'officeName'].includes(change.field),
                );
          return (
            <RenderChange
              change={{
                field: 'addressName',
                label: '',
                value: getCustomerAddressRowData(
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id]
                    : changeData.addresses[rowData.id],
                ).addressName,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'unitNumber',
        header: t('customersScreen.addressInfo.table.unitNumber'),
        cell: info => {
          const rowData = info.row.original;

          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change =>
                  ['roomNumber', 'apartmentNumber'].includes(change.field),
                );
          return (
            <RenderChange
              change={{
                field: 'unitNumber',
                label: '',
                value: getCustomerAddressRowData(
                  rowData.action === 'remove'
                    ? originalData.addresses[rowData.id]
                    : changeData.addresses[rowData.id],
                ).unitNumber,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={addresses}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            sortPriority: 'center',
            active: 'center',
            name: 'center',
            posName: 'center',
            isSelected: 'center',
          }}
          title={t('customersScreen.addressInfo.title')}
        />
      </Box>
    );
  };

  const renderCardsTable = (cards: ISpecialTagDiff[]) => {
    const columnHelper = createColumnHelper<ISpecialTagDiff>();
    const defaultColumns = [
      columnHelper.accessor('changes', {
        id: 'isDefault',
        header: t('customersScreen.addressInfo.table.isDefault'),
        cell: info => {
          const rowData = info.row.original;
          const hasChanged =
            rowData.action === 'add'
              ? true
              : rowData.changes.some(change => change.field === 'isDefault');

          return (
            <RenderChange
              change={{
                field: 'isDefault',
                label: '',
                value: changeData.cards[rowData.id]?.isDefault,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={hasChanged}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'lastFourDigits',
        header: t('customersScreen.cardInfo.table.lastFourDigits'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'lastFourDigits',
                label: '',
                value: `**** **** **** ${changeData.cards[rowData.id]
                  ?.lastFourDigits}`,
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={false}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'cardType',
        header: t('customersScreen.cardInfo.table.cardType'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'cardType',
                label: '',
                value: t(
                  `customersScreen.cardInfo.cardType.${changeData.cards[
                    rowData.id
                  ]?.cardType}`,
                ),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={false}
            />
          );
        },
      }),
      columnHelper.accessor('changes', {
        id: 'dateOfLastUsed',
        header: t('customersScreen.cardInfo.table.dateOfLastUsed'),
        cell: info => {
          const rowData = info.row.original;
          return (
            <RenderChange
              change={{
                field: 'dateOfLastUsed',
                label: '',
                value: DateTime.fromISO(
                  changeData.cards[rowData.id]?.dateOfLastUsed,
                ).toFormat('MM/dd/yy'),
              }}
              formatValue={formatValue}
              showLabel={false}
              hasChanged={false}
            />
          );
        },
      }),
    ];
    return (
      <Box csx={{ width: '100%', paddingBlock: '10px' }}>
        <Table
          data={cards}
          columns={defaultColumns}
          cellCsx={{ height: '70px' }}
          showShadow={false}
          alignHeaders={{
            isDefault: 'center',
            lastFourDigits: 'center',
            cardType: 'center',
            dateOfLastUsed: 'center',
          }}
          title={t('customersScreen.cardInfo.title')}
        />
      </Box>
    );
  };

  return (
    <Box>
      {formattedDifferences && (
        <>
          {formattedDifferences.basic.length > 0 && (
            <>
              <HeaderDiffModal
                name={t('app.modals.diffModal.sectionTitles.basic')}
              />
              <Grid columnGap={20} rowGap={10}>
                {formattedDifferences.basic.map(change => (
                  <Grid.Item
                    mb={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={`basic_change_${change.field}`}>
                    {renderChange(change)}
                  </Grid.Item>
                ))}
              </Grid>
            </>
          )}
          {formattedDifferences.addressInfo.length > 0 && (
            <>
              <HeaderDiffModal name={t('customersScreen.addressInfo.title')} />
              {renderAddressTable(formattedDifferences.addressInfo)}
            </>
          )}
          {formattedDifferences.cardsInfo.length > 0 && (
            <>
              <HeaderDiffModal name={t('customersScreen.cardInfo.title')} />
              {renderCardsTable(formattedDifferences.cardsInfo)}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomerDiffModal;
