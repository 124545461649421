import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import TextAreaInput from '@app/components/common/TextAreaInput';
import { makeSelectItemData } from '@app/state/menu/menuSelectors';
import { bindActionCreators } from '@reduxjs/toolkit';
import { SectionId, actionCreatorsMenu } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const SECTION_ID = SectionId.MORE_INFO;

interface IMoreInformationSection extends WithTranslation, IAccordionSection {
  bucket?: 'categories' | 'subcategories';
}

const MoreInformationSection = ({
  t,
  bucket,
  ...props
}: IMoreInformationSection) => {
  const { updateMenuType: setValue } = bindActionCreators(
    actionCreatorsMenu,
    useDispatch(),
  );

  const getMemoizedItemData = useMemo(
    () => makeSelectItemData(bucket as 'categories', SECTION_ID),
    [bucket],
  );

  const { itemData } = useSelector(getMemoizedItemData);

  return (
    <AccordionSection
      title={t('menuScreen.itemDetails.moreInformation.title')}
      {...props}>
      <Grid rowGap={15} columnGap={15}>
        <Grid.Item mb={9} xl={11}>
          <TextAreaInput
            maxLength={1000}
            value={itemData?.description || ''}
            onChange={e => {
              setValue({
                description: e.currentTarget.value,
              });
            }}
            placeholder={t('loggedIn.commonFields.moreInformation.description')}
            label={t('loggedIn.commonFields.moreInformation.description')}
          />
        </Grid.Item>
        <Grid.Item
          mb={3}
          xl={1}
          csx={{
            flexDirection: 'row',
            height: '100%',
            alignItems: 'flex-end',
          }}>
          <Button
            variant="primary"
            onClick={() => {
              setValue({
                description: '',
              });
            }}
            disabled={itemData?.description === ''}
            csx={{
              height: '90px',
            }}>
            {t('commonButtons.clear')}
          </Button>
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default MoreInformationSection;
