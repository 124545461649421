import Button from '@app/components/common/Button';
import Grid from '@app/components/common/Grid';
import Icon from '@app/components/common/Icon';
import { labelStyles } from '@app/components/common/Input/styles';
import { TCsx } from '@emotion/react';
import { CustomerAddressType } from '@westondev/tableturn-core';
import { WithTranslation } from 'react-i18next';

type IAddressTypeSwitch = WithTranslation & {
  onChange: (addressType: CustomerAddressType) => void;
  value: CustomerAddressType;
  csx?: TCsx;
};

const AddressTypeSwitch = ({ t, value, onChange, csx }: IAddressTypeSwitch) => {
  return (
    <Grid rowGap={15} columnGap={15} csx={csx}>
      <Grid.Item>
        <label css={labelStyles}>
          {t('customersScreen.addressInfo.table.addressType')}
          <span className="required">*</span>
        </label>
      </Grid.Item>
      <Grid.Item mb={6} sm={3}>
        <Button
          variant={value === CustomerAddressType.HOUSE ? 'active' : undefined}
          icon={<Icon name="FaHouseChimney" size="30px" />}
          onClick={() => onChange(CustomerAddressType.HOUSE)}>
          {t('customersScreen.addressInfo.addressType.house')}
        </Button>
      </Grid.Item>
      <Grid.Item mb={6} sm={3}>
        <Button
          variant={value === CustomerAddressType.HOTEL ? 'active' : undefined}
          icon={<Icon name="MdHotel" size="30px" />}
          onClick={() => onChange(CustomerAddressType.HOTEL)}>
          {t('customersScreen.addressInfo.addressType.hotel')}
        </Button>
      </Grid.Item>
      <Grid.Item mb={6} sm={3}>
        <Button
          variant={value === CustomerAddressType.OFFICE ? 'active' : undefined}
          icon={<Icon name="BsBuildingFill" size="30px" />}
          onClick={() => onChange(CustomerAddressType.OFFICE)}>
          {t('customersScreen.addressInfo.addressType.office')}
        </Button>
      </Grid.Item>
      <Grid.Item mb={6} sm={3}>
        <Button
          variant={
            value === CustomerAddressType.APARTMENT ? 'active' : undefined
          }
          icon={<Icon name="MdApartment" size="30px" />}
          onClick={() => onChange(CustomerAddressType.APARTMENT)}>
          {t('customersScreen.addressInfo.addressType.apartment')}
        </Button>
      </Grid.Item>
    </Grid>
  );
};

export default AddressTypeSwitch;
