import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import {
  selectCategories,
  selectSubcategories,
} from '@app/state/menu/menuSelectors';
import { currentTaxesSettingsSelector } from '@app/state/selectors/settingsSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  ICategoryTableRow,
  IPair,
  iPairSortingValues,
  menuCategoriesTableFactoryCore,
  nullUndefineSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import OverflowText from '../../../Items/ItemsList/ItemsTable/OverflowText';
import TaxColumn from '../../../Items/ItemsList/ItemsTable/TaxColumn';
import { IMainScreenTable } from '../../../Items/ItemsList/ItemsTable/types';
import NameLink from '../../../ModifierGroups/ModifierGroupsTable/NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const CategoriesTable = ({ filterParams, t }: IMainScreenTable) => {
  const categories = useSelector(selectCategories);
  const subcategories = useSelector(selectSubcategories);
  const taxes = useSelector(currentTaxesSettingsSelector);

  const onSort = useSort<ICategoryTableRow>('title');

  const categoriesArray = menuCategoriesTableFactoryCore(
    categories,
    subcategories,
    taxes,
  );

  const categoriesArrayFiltered = useMemo(
    () =>
      filter<ICategoryTableRow>(
        categoriesArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, categoriesArray],
  );

  const customFilterFn = (row: Row<ICategoryTableRow>) => {
    return (
      row.depth > 0 ||
      categoriesArrayFiltered.findIndex(
        category => category.id === row.original.id,
      ) !== -1
    );
  };

  const iPairSortingFn = (
    rowA: Row<ICategoryTableRow>,
    rowB: Row<ICategoryTableRow>,
    columnId: string,
  ) => {
    return iPairSortingValues(
      rowA.original?.[columnId as keyof ICategoryTableRow] as IPair[],
      rowB.original?.[columnId as keyof ICategoryTableRow] as IPair[],
    );
  };

  const nullUndefinedSortingFn = (
    rowA: Row<ICategoryTableRow>,
    rowB: Row<ICategoryTableRow>,
    columnId: string,
  ) => {
    return nullUndefineSortingValues(
      rowA.original[columnId as keyof ICategoryTableRow] as string,
      rowB.original[columnId as keyof ICategoryTableRow] as string,
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => {
          return (
            <NameLink
              name={info.getValue()}
              path={`/menu/categories/${info.row.original.id}`}
              status={info.row.original.status}
              statusPath={`selectedMode.currentMenu.categories.${info.row.original.id}.active`}
            />
          );
        },
        size: 300,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.categoryMain.table.headers.itemCount'),
        cell: info => {
          return (
            <Box csx={{ display: 'flex', width: '100%' }}>
              <Box csx={{ flex: 1 }}>{info.row.original.itemCount}</Box>
              <Box csx={{ flex: 1 }}>{info.row.original.comboCount}</Box>
            </Box>
          );
        },
        size: 150,
      }),
      columnHelper.accessor('subcategories', {
        header: t('menuScreen.common.bucket.subcategories.plural'),
        cell: info => {
          return (
            <OverflowText
              text={
                info.getValue() ||
                t('menuScreen.categoryMain.table.noSubcategories')
              }
            />
          );
        },
        sortingFn: nullUndefinedSortingFn,
        sortDescFirst: false,
        minSize: 300,
      }),
      columnHelper.accessor('taxes', {
        header: t('menuScreen.itemsMain.table.headers.taxes'),
        cell: info => {
          return <TaxColumn truncateLength={20} value={info.getValue()} />;
        },
        sortingFn: iPairSortingFn,
        sortDescFirst: false,
        size: 300,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={categoriesArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default CategoriesTable;
