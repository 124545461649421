import { getTimeFromUTC } from '@app/helpers/time/time';
import { useEffect, useState } from 'react';
import Input from '../Input';
import { IInput } from '../Input/Input';

const convertTimeToUTC = (time: string) => {
  const [hours, minutes] = time.split(':');
  return `2023-12-25T${hours}:${minutes}:00Z`;
};

type TimePickerProps = Omit<IInput, 'onChange'> & {
  utcValue: string;
  onChange: (newValue: string) => void;
  minValue?: string;
  maxValue?: string;
};

const TimePicker = ({
  utcValue,
  onChange,
  minValue = '00:00',
  maxValue = '23:59',
  ...otherProps
}: TimePickerProps) => {
  const [inputValue, setInputValue] = useState(utcValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputtedTime = e.target.value;
    const convertedTime = convertTimeToUTC(inputtedTime);
    setInputValue(inputtedTime);
    onChange(convertedTime);
  };

  useEffect(() => {
    setInputValue(getTimeFromUTC(utcValue, false, false));
  }, [utcValue]);

  return (
    <Input
      type="time"
      value={inputValue}
      onChange={handleChange}
      min={minValue}
      max={maxValue}
      {...otherProps}
    />
  );
};

export default TimePicker;
