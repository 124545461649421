import Box from '@app/components/common/Box';
import Table from '@app/components/common/Table';
import Typography from '@app/components/common/Typography';
import {
  selectModifierGroups,
  selectModifierItems,
} from '@app/state/menu/menuSelectors';
import { FilterFns, Row } from '@tanstack/react-table';
import {
  filter,
  IModifierGroupTableRow,
  menuModifierGroupsTableFactoryCore,
  stringSortingValues,
  useSort,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IMainScreenTable } from '../../Items/ItemsList/ItemsTable/types';
import NameLink from './NameLink';
import { columnHelper, ROW_ID_PREFIX } from './types';

const ModifierGroupsTable = ({ filterParams, t }: IMainScreenTable) => {
  const modifierGroups = useSelector(selectModifierGroups);
  const modifierItems = useSelector(selectModifierItems);

  const onSort = useSort<IModifierGroupTableRow>('title');

  const modifierGroupsArray = menuModifierGroupsTableFactoryCore(
    modifierGroups,
    modifierItems,
  );

  const modifierGroupsArrayFiltered = useMemo(
    () =>
      filter<IModifierGroupTableRow>(
        modifierGroupsArray,
        { ...filterParams } as {
          [key: string]:
            | number
            | string
            | {
                [key: string]: number | string;
              }
            | number[]
            | string[];
        },
        null,
        onSort,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterParams, modifierGroupsArray],
  );

  const customFilterFn = (row: Row<IModifierGroupTableRow>) => {
    return (
      row.depth > 0 ||
      modifierGroupsArrayFiltered.findIndex(
        modifierGroup => modifierGroup.id === row.original.id,
      ) !== -1
    );
  };

  const COLUMN_VALUES = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: t('menuScreen.itemsMain.table.headers.name'),
        filterFn: 'customFilterFn' as keyof FilterFns,
        cell: info => (
          <NameLink
            name={info.getValue()}
            path={`/menu/modifiers/modifier-groups/${info.row.original.id}`}
            status={info.row.original.status}
            statusPath={`selectedMode.currentMenu.modifierGroups.${info.row.original.id}.active`}
          />
        ),
        size: 200,
      }),
      columnHelper.accessor('quantityMode', {
        header: t('menuScreen.modifierGroupsMain.table.headers.quantityMode'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 150,
      }),
      columnHelper.accessor('maximumModifierItems', {
        header: t(
          'menuScreen.modifierGroupsMain.table.headers.maximumModifierItems',
        ),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 100,
      }),
      columnHelper.accessor('includedModifierItems', {
        header: t(
          'menuScreen.modifierGroupsMain.table.headers.includedModifierItems',
        ),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 100,
      }),
      columnHelper.accessor('requiredModifierItems', {
        header: t(
          'menuScreen.modifierGroupsMain.table.headers.requiredModifierItems',
        ),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 100,
      }),
      columnHelper.accessor('modifierItems', {
        header: t('menuScreen.modifierGroupsMain.table.headers.modifierItems'),
        cell: info => {
          const modItems = info.getValue();
          const isEmpty = !modItems.length;
          return (
            <Typography
              csx={{
                fontStyle: isEmpty ? 'italic' : undefined,
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                flexWrap: 'wrap',
              }}>
              {!isEmpty
                ? modItems.map((modItem, index) => (
                    <Typography
                      component="span"
                      color={modItem.isDefault ? 'semanticBlue' : undefined}
                      key={`modItem-${index}`}>
                      {modItem.text}
                      {index !== modItems.length - 1 && ', '}
                    </Typography>
                  ))
                : t('menuScreen.modifierGroupsMain.table.noModifierItems')}
            </Typography>
          );
        },
        sortingFn: (rowA, rowB) => {
          const aValue = rowA.original.modifierItems;
          const bValue = rowB.original.modifierItems;

          const isAEmpty = !aValue.length;
          const isBEmpty = !bValue.length;

          if (isAEmpty && isBEmpty) {
            return 0;
          }

          if (isAEmpty && !isBEmpty) {
            return 1;
          }

          if (!isAEmpty && isBEmpty) {
            return -1;
          }

          return stringSortingValues(aValue[0].text, bValue[0].text);
        },
        sortDescFirst: false,
        minSize: 200,
      }),
      columnHelper.accessor('itemCount', {
        header: t('menuScreen.modifierGroupsMain.table.headers.itemCount'),
        cell: info => <Typography>{info.getValue()}</Typography>,
        size: 100,
      }),
    ],
    [t],
  );

  const tableFilters = useMemo(
    () =>
      Object.entries(filterParams || {})
        .filter(([key]) =>
          COLUMN_VALUES.some(column => column.accessorKey === key),
        )
        .map(([key, filterParam]) => {
          return {
            id: key,
            value: filterParam,
          };
        }),
    [filterParams, COLUMN_VALUES],
  );

  return (
    <Box
      csx={{
        padding: '15px',
        height: '100%',
      }}>
      <Table
        scrollEnabled
        filterFns={{ customFilterFn }}
        columnFilters={tableFilters}
        columnSorting={[{ id: 'title', desc: false }]}
        enableAlphabeticalSorting
        alwaysShowSortIcon
        nestedScrollEnabled
        data={modifierGroupsArray}
        manualSorting={false}
        columns={COLUMN_VALUES}
        cellCsx={{
          height: '70px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        rowIdPrefix={ROW_ID_PREFIX}
        renderEmptyValues
      />
    </Box>
  );
};

export default ModifierGroupsTable;
