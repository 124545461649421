import Button from '@app/components/common/Button';
import Icon from '@app/components/common/Icon';
import { IItemSelection } from '@app/components/common/SelectionModal/GenericSelectionModal/types';
import { MenuTypeStatus } from '@app/components/common/SelectionModal/MenuTypesSelectionModal/Filter/types';
import UsersSelectionModal from '@app/components/common/SelectionModal/UsersSelectionModal';
import { emailReportMobile } from '@app/state/reports/reportsWebActions';
import { selectLoggedInUser } from '@app/state/selectors/appSelectors';
import { useAppDispatch } from '@app/state/store';
import { MQ_MAX_MEDIUM } from '@app/theme/types';
import {
  usersSelectors,
  settingsSelectors,
  usersCardFactory,
} from '@westondev/tableturn-core';
import { useMemo, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface IEmailReportButton extends WithTranslation {
  isLoading: boolean;
  isDisabled: boolean;
}

const EmailReportButton = ({
  t,
  isLoading,
  isDisabled,
}: IEmailReportButton) => {
  const dispatch = useAppDispatch();

  const currentOrgUserId = useSelector(selectLoggedInUser)?.organizationUserId;
  const users = useSelector(usersSelectors.selectUsers);
  const roles = useSelector(settingsSelectors.selectRoles);

  const formattedUsersArray = useMemo(() => {
    return usersCardFactory(users).map(userCard => ({
      ...userCard,
      roles: userCard.roleIds.map(roleId => roles[roleId]?.name),
      description: userCard.email,
      isActive: undefined,
    }));
  }, [users, roles]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const closeModal = () => setIsModalOpen(false);

  const handleOnAssociate = (selectedUsers: IItemSelection[]) => {
    const selectedUserIds = selectedUsers?.map(user => Number(user.id)) ?? [];
    if (!selectedUserIds.length) return;
    closeModal();
    setIsSendingEmail(true);
    dispatch(emailReportMobile(selectedUserIds)).finally(() =>
      setIsSendingEmail(false),
    );
  };

  return (
    <>
      <UsersSelectionModal
        buttons={formattedUsersArray}
        selectedButtonIds={[currentOrgUserId]}
        onAssociate={handleOnAssociate}
        onModalClose={closeModal}
        active={isModalOpen}
        allowAssociateMultiple
        showDescriptionPanel={false}
        associateOnSelect={false}
        showSelectedButtons
        showCommonFilter={false}
        defaultFilterValue={MenuTypeStatus.all}
        buttonsContainerCsx={{
          gridTemplateColumns:
            'repeat(auto-fill, minmax(240px, 1fr)) !important',
        }}
        currentButtonInfo={undefined}
        btnSuccessText={t('commonButtons.send')}
      />
      <Button
        csx={{
          width: 174,
          [MQ_MAX_MEDIUM]: {
            width: 60,
            '> span.icon.icon-left': {
              paddingRight: '0px',
              position: 'relative',
            },
          },
        }}
        icon={<Icon name="MdOutlineEmail" />}
        variant="secondary"
        onClick={() => setIsModalOpen(true)}
        isLoading={isLoading || isSendingEmail}
        disabled={isDisabled || isSendingEmail}>
        <span
          css={{
            [MQ_MAX_MEDIUM]: {
              display: 'none',
            },
          }}>
          {t('commonTexts.email')}
        </span>
      </Button>
    </>
  );
};

export default EmailReportButton;
