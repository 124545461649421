import AccordionSection from '@app/components/common/Accordion/AccordionSection';
import { IAccordionSection } from '@app/components/common/Accordion/AccordionSection/types';
import Box from '@app/components/common/Box';
import Checkbox from '@app/components/common/Checkbox';
import Dropdown from '@app/components/common/Dropdown';
import Grid from '@app/components/common/Grid';
import Input from '@app/components/common/Input';
import useRootSelector from '@app/hooks/useRootSelector';
import { selectCardMenuTypeList } from '@app/state/menu/menuSelectors';
import { settingsErrorsSelector } from '@app/state/selectors/settingsSelectors';
import { displayFlexEndColumnStyles } from '@app/theme/commonStyles';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  ILicenseSettingsStore,
  actionCreatorsSettingsChangeData,
  displayModeValue,
  fontSizeValue,
  getDisplayModeDropdownOptions,
  getFontSizeDropdownOptions,
  getLanguageDropdownOptions,
  getLicensesSettingsId,
  languageValue,
  restaurantModeDropdownOptions,
  restaurantModeValue,
  valueToDisplayMode,
  valueToFontSize,
  valueToLanguage,
  valueToRestaurantMode,
} from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface ILicenseBasicSettings extends IAccordionSection {
  data: ILicenseSettingsStore;
  updateInfo: (
    field: keyof ILicenseSettingsStore,
    newValue: boolean | number | string | null,
  ) => void;
}

const LicenseBasicSettings = ({
  data,
  updateInfo,
  ...sectionProps
}: ILicenseBasicSettings) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isExpanded, ...otherProps } = sectionProps;

  // Redux
  const revenueCenters = useRootSelector(
    state => state.tableLayout.savedData.revenueCenters,
  );
  const menuTypesFactory = useSelector(selectCardMenuTypeList);
  const settingsErrors = useSelector(settingsErrorsSelector);
  const { clearSettingsError } = bindActionCreators(
    actionCreatorsSettingsChangeData,
    useDispatch(),
  );
  const revenueCentersList = () => {
    const revenueData = Object.values(revenueCenters).map(revenueCenter => ({
      label: revenueCenter.name,
      value: revenueCenter.id,
    }));

    revenueData.unshift({
      label: t('commonTexts.default'),
      value: 0,
    });

    return revenueData;
  };

  // Local state
  const menuTypesOptions = useMemo(() => {
    const currentMenuTypesOptions = menuTypesFactory.map(value => ({
      value: value.id,
      label: [
        value.title,
        value.isActive ? '' : ` (${t('commonTexts.inactive')})`,
      ].join(''),
      textProps: value.isActive
        ? undefined
        : { color: 'persistentSemanticRed' },
    }));
    currentMenuTypesOptions.unshift({
      value: 0,
      label: t('commonTexts.default'),
      textProps: undefined,
    });
    return currentMenuTypesOptions;
  }, [menuTypesFactory, t]);

  return (
    <AccordionSection
      isExpanded={true}
      isExpandable={false}
      expandedHeight="70px"
      title={t('settingsModule.licenseSettings.basicSettings.title')}
      description={t(
        'settingsModule.licenseSettings.basicSettings.description',
      )}
      {...otherProps}>
      <Grid columnGap={20} rowGap={20}>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Input
            required
            label={t('settingsModule.licenseSettings.basicSettings.nickname')}
            placeholder={t(
              'settingsModule.licenseSettings.basicSettings.nickname',
            )}
            error={Boolean(settingsErrors[getLicensesSettingsId('nickname')])}
            value={data.nickname}
            onChange={e => updateInfo('nickname', e.currentTarget.value)}
            onFocus={() =>
              clearSettingsError(getLicensesSettingsId('nickname'))
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Input
            required
            type="number"
            label={t(
              'settingsModule.licenseSettings.basicSettings.stationNumber',
            )}
            placeholder={t(
              'settingsModule.licenseSettings.basicSettings.stationNumber',
            )}
            error={Boolean(
              settingsErrors[getLicensesSettingsId('stationNumber')],
            )}
            value={data.stationNumber}
            onChange={e =>
              updateInfo('stationNumber', Number(e.currentTarget.value))
            }
            onFocus={() =>
              clearSettingsError(getLicensesSettingsId('stationNumber'))
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.basicSettings.revenueCenter',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[revenueCentersList()]}
            value={data.revenueCenterId === null ? 0 : data.revenueCenterId}
            onChange={value =>
              updateInfo('revenueCenterId', value === 0 ? null : value)
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Input
            label={t(
              'settingsModule.licenseSettings.basicSettings.registerAutoLogout',
            )}
            placeholder={t(
              'settingsModule.licenseSettings.basicSettings.autoLogoutPlaceholder',
            )}
            info={t(
              'settingsModule.licenseSettings.basicSettings.registerAutoLogouttoolTip',
            )}
            value={
              data.registerAutoLock === null
                ? ''
                : String(data.registerAutoLock)
            }
            onChange={e => {
              const value = e.currentTarget.value;
              updateInfo(
                'registerAutoLock',
                value.length === 0
                  ? null
                  : isNaN(Number(value))
                  ? ''
                  : Number(value),
              );
            }}
            onFocus={() =>
              clearSettingsError(getLicensesSettingsId('registerAutoLock'))
            }
            error={Boolean(
              settingsErrors[getLicensesSettingsId('registerAutoLock')],
            )}
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t('settingsModule.licenseSettings.basicSettings.language')}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={getLanguageDropdownOptions()}
            value={languageValue[data.language]}
            onChange={value => updateInfo('language', valueToLanguage[value])}
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.basicSettings.displayMode',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={getDisplayModeDropdownOptions()}
            value={displayModeValue[data.displayMode]}
            onChange={value =>
              updateInfo('displayMode', valueToDisplayMode[value])
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t('settingsModule.licenseSettings.basicSettings.fontSize')}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={getFontSizeDropdownOptions()}
            value={fontSizeValue[data.fontSize]}
            onChange={value => updateInfo('fontSize', valueToFontSize[value])}
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3} csx={displayFlexEndColumnStyles}>
          <Dropdown
            label={t(
              'settingsModule.licenseSettings.basicSettings.restaurantMode',
            )}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={restaurantModeDropdownOptions()}
            value={
              restaurantModeValue[
                data.overrideRestaurantMode !== null
                  ? data.overrideRestaurantMode
                  : 'default'
              ]
            }
            onChange={value =>
              updateInfo('overrideRestaurantMode', valueToRestaurantMode[value])
            }
          />
        </Grid.Item>
        <Grid.Item sm={6} lg={4} xl={3}>
          <Dropdown
            label={t('settingsModule.licenseSettings.basicSettings.menu')}
            placeholder={t('commonTexts.placeholderDropdown')}
            data={[menuTypesOptions]}
            value={
              data.overrideDefaultMenuTypeId === null
                ? 0
                : data.overrideDefaultMenuTypeId
            }
            onChange={value =>
              updateInfo(
                'overrideDefaultMenuTypeId',
                value === 0 ? null : value,
              )
            }
          />
        </Grid.Item>
        <Grid.Item
          sm={6}
          lg={4}
          xl={3}
          csx={{
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}>
          <Box
            csx={{
              display: 'flex',
              alignItems: 'center',
              height: '50px',
            }}>
            <Checkbox
              label={t(
                'settingsModule.licenseSettings.basicSettings.suppressSuggestiveOrderingPrompts',
              )}
              checked={data.suppressSuggestiveOrdering}
              onChange={suppressSuggestiveOrdering =>
                updateInfo(
                  'suppressSuggestiveOrdering',
                  suppressSuggestiveOrdering,
                )
              }
            />
          </Box>
        </Grid.Item>
      </Grid>
    </AccordionSection>
  );
};

export default LicenseBasicSettings;
