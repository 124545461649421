import Box from '@app/components/common/Box';
import Button from '@app/components/common/Button';
import { actionCreatorsApp } from '@app/state';
import { CONFIRMATION_MODAL_INITIAL_STATE } from '@app/state/app/reducers';
import { IConfirmationModal } from '@app/state/app/types';
import { selectCustomersErrors } from '@app/state/customers/customersSelectors';
import {
  displayFlexColumnStyles,
  displayFlexRowStyles,
} from '@app/theme/commonStyles';
import { useTheme } from '@emotion/react';
import { CustomersErrorsId } from '@westondev/tableturn-core';
import { useMemo } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Divider from '../../Divider';
import InfoIcon from '../../Icon/custom/InfoIcon';
import Modal from '../../Modal';
import Typography from '../../Typography';
import SwitchError from '../SettingsValidationConfirmationModal/SwitchError';
import { btnBaseStyles } from '../styles';

interface ISettingsValidationConfirmationModal
  extends IConfirmationModal,
    WithTranslation {
  canDiscard?: boolean;
}

const CustomerValidationConfirmationModal = ({
  active,
  onCancel,
  onSuccess,
  btnCommonCsx: btnCommonCsx,
  btnCancelCsx: btnCancelCsx,
  runCancelOnClickOutside,
  size,
  t,
  autoclose = true,
  autoCloseOnCancel = true,
  canDiscard,
}: ISettingsValidationConfirmationModal) => {
  // Redux
  const { setShowConfirmationModal } = bindActionCreators(
    actionCreatorsApp,
    useDispatch(),
  );

  const customerErrors = useSelector(selectCustomersErrors);

  const theme = useTheme();

  const handleOnModalClose = () => {
    if (runCancelOnClickOutside && onCancel) {
      onCancel();
    }
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    autoCloseOnCancel &&
      setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const handleOnModalSuccess = () => {
    if (onSuccess) onSuccess();
    autoclose && setShowConfirmationModal(CONFIRMATION_MODAL_INITIAL_STATE);
  };

  const [groupedErrors, individualErrors] = useMemo(() => {
    const grouped: Array<{ data: any; id: string; type: CustomersErrorsId }> =
      [];
    const individual: Array<{
      data: any;
      id: string;
      type: CustomersErrorsId;
    }> = [];
    Object.entries(customerErrors).forEach(([key, value]) => {
      if (!value.kind) {
        individual.push({ data: value.data, id: key, type: value.id });
        return;
      }

      const hasGroupEntry = grouped.some(
        groupedError => groupedError.id === value.kind?.id,
      );
      if (!hasGroupEntry) {
        grouped.push({
          data: value.kind.data,
          id: value.kind.id,
          type: value.kind.type,
        });
      }
    });
    return [grouped, individual];
  }, [customerErrors]);

  return (
    <Modal
      isActive={active}
      size={size || 500}
      showXCloser={false}
      animationMode="alert"
      title={
        <Box csx={[displayFlexRowStyles, { gap: '10px' }]}>
          <InfoIcon
            backgroundColor={theme.colors.semanticBlue}
            color={theme.colors.textWhite}
            size="12px"
          />
          <Box>
            <Typography variant="subtitle" fontWeight="medium">
              {t('validations.validationFailed', {
                bucket: t('customersScreen.singular'),
              })}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <>
          {canDiscard && (
            <Button
              variant="secondary"
              csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
              onClick={handleOnModalSuccess}>
              {t('commonButtons.discardChanges')}
            </Button>
          )}
          <Button
            variant="secondary"
            csx={[btnBaseStyles, btnCommonCsx, btnCancelCsx]}
            onClick={handleOnCancel}>
            {t('commonButtons.ok')}
          </Button>
        </>
      }
      onModalClose={handleOnModalClose}>
      <Box
        csx={{
          display: 'flex',
          width: '100%',
        }}>
        <Box
          csx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowWrap: 'anywhere',
          }}>
          <Typography fontWeight="medium">
            {t(
              canDiscard
                ? 'validations.discardSubtitle'
                : 'validations.subtitle',
            )}
          </Typography>
          <Divider
            label={t('validations.issues')}
            csx={{ marginBlock: '15px' }}
          />
          <Box csx={[displayFlexColumnStyles, { gap: '10px' }]}>
            {groupedErrors.map(groupedError => (
              <SwitchError
                type={groupedError.type}
                data={groupedError.data}
                key={groupedError.id}
              />
            ))}
            {individualErrors.map(groupedError => (
              <SwitchError
                type={groupedError.type}
                data={groupedError.data}
                key={groupedError.id}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerValidationConfirmationModal;
